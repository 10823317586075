.shopify-section--header {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5
}

.Header {
  background: #fff;
  color: #1c1b1b;
  -webkit-box-shadow: 0 -1px #ddd inset;
  box-shadow: 0 -1px #ddd inset;
  -webkit-transition: background 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: background 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out
}


.Header .Heading,
.Header .Link--secondary,
.Header .Link--primary:hover {
    color: #1c1b1b
}

.Header .Text--subdued,
.Header .Link--primary,
.Header .Link--secondary:hover {
    color: #6a6a6a
}

@media screen and (min-width: 1240px) {
  .js .Header__Wrapper {
      opacity: 0;
      -webkit-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out
  }
  .Header:not(.Header--sidebar) .Header__Wrapper {
      padding: 18px 50px
  }
  .Header__CurrencySelector {
      display: inline-block
  }
  .Header:not(.Header--sidebar) .Header__FlexItem--fill {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0px;
      flex: 1 0 0
  }
  .Header__FlexItem:last-child {
      text-align: right
  }
  .Header--inline .Header__FlexItem:first-child .Header__CurrencySelector {
      display: none
  }
  .Header--center .Header__Wrapper.Header__Wrapper {
      padding-bottom: 24px
  }
  .Header--center .Header__MainNav {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 18px;
      width: 100%;
      text-align: center
  }
  .Header--center .Header__MainNav .HorizontalList {
      margin-left: 0;
      margin-right: 0
  }
  .Header--center .Header__FlexItem {
      margin-bottom: 40px
  }
  .Header--center .Header__FlexItem:last-child .Header__CurrencySelector {
      display: none
  }
  .Header--initialized .Header__Wrapper {
      opacity: 1
  }
}

@media screen and (min-width: 641px) {
  .Header__Wrapper {
      padding: 18px 30px
  }
  .Header__LogoImage {
      max-width: 100%
  }
  .Header__Icon .Icon--nav-desktop {
      height: 17px;
      width: 24px
  }
  .Header__Icon .Icon--cart-desktop {
      height: 23px;
      width: 19px
  }
}

.Header__Wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 18px
}

.Header__Logo {
  position: relative;
  margin-bottom: 0
}

.Header__LogoLink,
.Header__LogoImage {
  display: block
}

.Header__LogoImage {
  max-width: 90px;
  margin: 0 auto;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out
}

.supports-sticky .Header--transparent .Header__LogoImage--primary:not(:only-child) {
  opacity: 0
}

.supports-sticky .Header--transparent .Header__LogoImage--transparent {
  opacity: 1
}

.Header__LogoImage--transparent {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0
}
