 :root {
  --input-padding-x: .75rem;
  --input-padding-y: .75rem;
}

html,
body {
  height: 100%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    
}

.circle-loader {
  border: 2.5px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 3.5em;
  height: 3.5em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
}
.checkmark.draw:after {
  animation-duration: 800ms;
  animation-timing-function: ease;
  animation-name: checkmark;
  transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 1.75em;
  width: 0.875em;
  transform-origin: left top;
  border-right: 3px solid #5cb85c;
  border-top: 3px solid #5cb85c;
  content: '';
  left: 0.77em;
  top: 1.75em;
  position: absolute;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.875em;
    opacity: 1;
  }
  40% {
    height: 1.75em;
    width: 0.875em;
    opacity: 1;
  }
  100% {
    height: 1.75em;
    width: 0.875em;
    opacity: 1;
  }
}


.react-phone-number-input__row {
  margin-top: 10px;
}


.react-phone-number-input__icon-image{
  vertical-align: top;
}
.react-phone-number-input__icon{
  border: none;
}

.mobile-field-image-container{
  display: inline-block;
}

.mobile-valid-box{
  height: 40px;
  width: 40px;
}
.react-phone-number-input {
  background-repeat:no-repeat;
  background-size:contain;
  border-right: none;
}

.valid-mobile {
  background-repeat:no-repeat;
  background-position: center right calc(1rem / 20);
  padding-right: 2.25rem;
  background-size: calc(3.5rem / 2) calc(2.25rem / 2);
  border: 1px solid;
  border-color: #CFD4D9;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-left: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
}

.invalid-mobile { 
  padding-right: 2.25rem;
  border: 1px solid;
  border-color: #dc3545;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-left: none;
  background-size: calc(3.5rem / 2) calc(2.25rem / 2);
  background-repeat:no-repeat;
  background-position: center right calc(1rem / 20);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
}

.-mobile{
  border: 1px solid #ced4da;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}



.invalid-mobile-box{
  border-color: #dc3545;
}
.mobile-icon{
  width: 20px;
  height: 20px;
}
.input-group-text{
  padding:0;
  background-color: transparent;
}
.react-phone-number-input__input{
  border-bottom: none;
  border: none;
  padding-top: 0px !important ;
  padding-bottom: 0px !important ; 
  
}
.mobile-label{
  font-size: 12px;
  color: #777;
  margin-top: 4px;
  margin-left: 10px;
  padding: 0px !important; 
  
}
@-webkit-keyframes autofill {
  100% {
      background: transparent;
      color: inherit;
  }
}
.form-container-checkout {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-control:focus {
  border-color: #313438;
  box-shadow: none;
}

.form-control {
    height: calc(2.25rem + 13px);
}



.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #CFD4D9;
  background-position: center right calc(1rem / 2.8);
  
}



.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color:#313438;
  box-shadow: none;
}


.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  box-shadow: none;
}

* {
  box-sizing: border-box;
}

.__PrivateStripeElement{
  top: 2px;
}
.StripeElement {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 12px;
  width: auto;
  margin-bottom: 0px;
  height: 45px;
}
.StripeElement--complete{
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 12px;
  width: auto;
  margin-bottom: 0px;
  background-size: calc(3.5rem / 2) calc(2.25rem / 2);
  background-repeat:no-repeat;
  background-position: center right calc(1rem / 20);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
}

.StripeElement--focus {
  border: 1px solid;
  border-color:#313438;
  outline: 0;
}


.StripeElement-invalid{
  border: 1px solid #dc3545;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 12px;
  width: auto;
  margin-bottom: 15px;
  background-size: calc(3.5rem / 2) calc(2.25rem / 2);
  background-repeat:no-repeat;
  background-position: center right calc(1rem / 20);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
}

.intercom-launcher-mobile{
  margin-bottom: '40px';
  padding-bottom: '40px';
}

.StripeElement-invalid:focus{
  border: 1px solid #dc3545;
}

.StripeElement--outlined{
  border: 1px solid #dc3545;
}

.ElementsApp input {
  font-size: 12px;
}


@media screen and (max-width: 600px) {
  .StripeElement {
    margin-bottom: 0px;
  }
  
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: 0 auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}
.form-checkbox{
  -webkit-appearance: checkbox;
}


.checkout-button-mobile {
  position:fixed;
  left:0px;
  bottom:0px;
  width:100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0px !important;
}

.checkout-button-desktop {
  margin-right: 'auto';
  margin-top: '0px';
  padding-top: 15px;
  padding-bottom: 15px;
 }


@media screen and (max-width: 600px) {
  .checkout-button-desktop {
   display: none;
  }
}

@media screen and (min-width: 600px) {
  .checkout-button-mobile {
   display: none;
  }
}



.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}
select:focus,
input{
  font-size: 16px !important;
}
select:focus,
select{
  font-size: 16px;
}