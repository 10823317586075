/* 
FLAYR CUSTOM START
*/

@import 'https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css';

.ProductMeta__PriceList.Heading a .spr-badge .spr-badge-caption:after {
    content:" - View All";
  }
  
  .text-cycle {
    display: none;
    margin-top: 20px;
  }
  
  .alert-box {
    margin-top: 20px;
    color: #ff4d4d;
  }
  inp
  #trialBooking {
      display:none;
    }

  .col-no-padding {
    padding: 0px
  }
    
  .custom-select {
    height: 54px;
    width: 100%;
    border: 1px solid #cfcfcf;
    position:relative;
  }
  .custom-select:hover {
    cursor: pointer;
  }
  
  .custom-select select {
    border: none;
    background: none;
    font-size: 16px;
    height: 54px;
    width: 100%;
    padding: 15px;
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none;       /* remove default arrow */
  }
  
  .custom-select svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
  
  #shopify-product-reviews .spr-summary-starrating,
  .spr-starrating .spr-summary-starrating,
  .spr-summary-caption {
    display: block  !important;
  }
  
  /*  Removes write review  */
  
  .spr-summary-actions {
    margin-top: 10px;
  }
  
  .spr-summary-actions-newreview {
    visibility: hidden;
    display: none;
  }
  
  .spr-summary-actions-togglereviews br + div {
    display: none;
    visibility: hidden;
  }
  
  .ProductMeta {
    text-align: left;
  }
  
  .ProductMeta__PriceList.Heading {
    margin-top: 0;
  }
  
  .spr-badge {
    font-size: 85%;
  }
  
  .spr-icon {
    color: #000000 !important;
  }
  
  #shopify-product-reviews .spr-pagination {
    display: block;
  }
  
  .spr-pagination-prev {
    display: none !important;
  }
    
  .Rte a:not(.Button) {
    text-decoration: none;
  }
  
  #quotenotification {
    background: #000000;
    color: #ffffff;
  }
  
  
  #quotenotification-red {
    background: #ff4d4d;
    color: #ffffff;
  }
  
  #quotenotification-green {
    background: #8cf2d9 ;
    color: #000000;
  }
  
  
  .quoteLine {
    display: block;
    overflow: hidden;
  }
  
  .quoteLine + hr {
    margin-top: 32px;
  }
  
  .quoteLine .quotePerson {
    display: inline-block;
    font-weight: 700;
    width: 200px;
  }
  
  .quoteLine .quoteService {
    display: inline-block;
    font-weight: 400;
    width: auto;
  }
  
  hr {
    border-top: 1px solid #cfcfcf;
  }
  
  .CollectionMain_Offers {
    margin-bottom: 20px;
  }
  
  .offer-item {
    /* margin-bottom: 6px; */
    /* rm override - compensated for this margin in the Grid cell white */
    /* border-bottom: 1px solid #DDDDDD; */
    line-height: 1.3;
  }
  
  .checkbox::after{
    background-color: black
  }

  .checkbox::before{
    background-color: white
  }

  .Form__Checkbox:checked ~ label::before {
    border-color: #1c1b1b;
    background-color: #388288;
  }
  .offer-stylist-photo {
    padding-left: 0 !important;
    margin-bottom: 0!important;
  }
  
  .offer-stylist-details {
    padding-left: 10px !important;
    margin-bottom: 0!important;
    text-align: left;
  }
  
  .offer-stylist-details .ProductItem__Title.Heading {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
  
  
  .offer-feature-image {
    position: relative;
    /* margin-bottom: 10px; */
    margin-bottom: 0px;
  }
  
  .offer-feature-image-price {
    display: inline-block;
    font-size: 12px;
    padding: 2px 6px;
    background: #000000;
    color: #ffffff;
    position: absolute;
    bottom: 1px;
    right: 0;
  }
  
  .offer-image-stylist-overlay {
    background: rgba(255,255,255,.75);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 4px;
    color: black;
  }
  
  .offer-image-stylist-overlay .Grid {
    margin: 0 !important;
  }
  
  .ProductItem__Title {
    margin-bottom: 0;
  }
  
  .spr-badge-caption {
    display: block;
    color: black;
  }
  
  
  
  /* 
  FLAYR MEDIA QUERIES MIXINS
  */
  
  /* SMALL ONLY */
  @media only screen and (max-width: 749px) {
    
    .clients {
        text-align: center;
    }
    
    .clients img {
      width: 55%;
      display: block;
      margin: 0 auto 10px auto;
    }
    
    .in-progress {
      text-align: center;
    }
    
    .loader {
      margin: 20px auto 20px auto;
    }
  
    .ProductMeta__Description--inner {
      padding: 0 24px;
    }
  
    .quoteLine {
      display: block;
      overflow: hidden;
    }
  
    .quoteLine .quotePerson {
      width: 50%;
      float: left;
      clear: right;
    }
  
    .quoteLine .quoteService {
      width: 50%;
      float: left;
    }
    
    .Timeline__ListItem {
        height: 450px;
    }
  
  
    .Container_Offers {
      padding: 0 24px;
    }
  
  }
  
  .Collapsible.Collapsible--large + #offers {
    margin-top: 40px;
  }
  
  /* MEDIUM UP */
  @media only screen and (min-width: 750px) {
    .ProductMeta__Description {
      border-top: 0;
    }
  }
  
  @media only screen and (min-width: 1240px) {
    .offer-image-stylist-overlay {
      padding: 10px;
    }
    
    .offer-stylist-details {
      margin-top: 5%;
    }
  
  }
  
  /* Get A Quote */
    .getaquote {
      background: black;
        background-color: black;
      color: white;
    
    }
    
    .getaquote .Section__Heading {
        color: white;
      
    }
    
    .getaquote .Button {
        color: #363636;
      border-color: #ffffff;
    }
    
    .getaquote .Button::before {
      background-color: white;
      color: #363636;
    }
  
  
  /* Override of styling for Makeup Artist Grid cards */
  .subdued--heading {
      text-transform: none;
        letter-spacing: 0;
        font-size: 0.8em
  }
  
  
  /* Shrink button spacing - mainly used for header */
  .Button--compressed {
      padding: 6px 7px;
        letter-spacing: 0;
        line-height: 1.5;
    text-transform: none;
  /*   	font-size: 0.6em */
  }
  
  /* Shrink button spacing - mainly used for header */
  .Button--thin {
    padding: 6px 24px;
}
  
  .Button--white{
      background-color: white;
  }
  
  
  
  .ArticleToolbar--red {
      background: #ff4d4d;
  }
  
  .Heading--white {
      color: white;
  }
  
  /* Extra Spacing for header */
  @media only screen and (max-width: 1000px) {
  .stylist-page-navbar-space {
    margin-top: 8em;
  }
}

/* RM - edit to make grid suit mobile layout for presenting stylist profile cards */
.Grid {
  margin-left: -12px;
  margin-right: -12px;
  overflow: hidden;
}

.Grid__Cell--12 {
  padding-left: 0px; 
}

.Grid__Cell--TopPad5 {
  padding-top: 4px; 
}

.Grid__Cell--white {
  background-color: #ffffff7a;
  margin-bottom: 6px;
  padding-left: 12px;
}

.Offer__PricingCard {
  padding-top: 0px;
  font-size: 0.85rem;
  color: black;
  border-top: 0;
  margin-top: 10px;
  margin-right: 33px;
}

.ViewProfile--rightbottom{
  /* font-size: 1.2rem; */
  /* color: #757575; */
  position: absolute;
  bottom: 3px;
  right: 3px;
  /* font-size: 1rem; */
  /* border: none; */
}

.small-profile-pic-fixedheight {
  /* border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px; */
  border-radius: 50%;
  height: 88px;
  width: 88px;
}

.Alert__Booked {
  color: #ff4d4d;
}

.Alert__Green{
  color: #339999;
  font-size: 0.8em;
}

.Alert__Stats{
  color: #ff4d4d;
  /* font-size: 0.8em; */
  
}

.Alert__Stats--cycling{
  animation:fading 5s infinite}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}
}

.instant-book-subtext{
  opacity: 0.7;
}

.material-icons {
  font-size: inherit;
}

.Viewprofile__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #757575;
  font-size: 2rem;
  right: -5px;
  /* font-weight: 200; */
}

.Card-bookedout {
  opacity: 0.33;
}

.Label__Great-Value {
  position: absolute;
  left: 0px;
  top: 2px;
  text-align: left;
}

.Label__Great-Value--bottomright {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #008489;
  letter-spacing: 0;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}
.reviewitem{
  height:500px;
}

.Label__Instant__Book {
  color: #008489;
  letter-spacing: 0;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.review-container{
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 1%;
  padding-right:1%;
  font-size:22px;
}

.dotsClass{
  background-color: black;
}
.form-box {
  padding: 30px;
  width: 30%;
  margin-left: 10%;
  background-color: white;
  border-color: linear-gradient(#25a9e0, #1b75bb,#2e3191, #7f3f97);
  border-image: linear-gradient(0.25turn, #25a9e0, #1b75bb,#2e3191, #7f3f97) 60;
  border-style: solid;
  border-width: 5px;  background-repeat: no-repeat;
  
}

.footer-link{
  color: #6c757d;
}

@media screen and (max-width: 2500px) {
  .form-box {
      padding: 30px;
      width: 35%;
      margin-left: 7%;
      background-color: white;
    
  }
}

@media screen and (max-width: 1400px) {
  .form-box {
      padding: 30px;
      width: 60%;
      margin-left: 0;
      background-color: white;
      

  }
}


@media screen and (max-width: 1020px) {
  .form-box {
      padding: 30px;
      width: 90%;
      margin-left: 0;
      background-color: white;
     

  }
}

@media screen and (max-width: 800px) {
  .form-box {
      padding: 30px;
      width: 80%;
      margin-left: 0;
      background-color: white;
   

  }
}

@media screen and (max-width: 600px) {
  .form-box {
      width: 100%;
      margin-left: 0;
      background-color: white;
      border-color: linear-gradient(#25a9e0, #1b75bb,#2e3191, #7f3f97);
      border-image: linear-gradient(0.25turn, #25a9e0, #1b75bb,#2e3191, #7f3f97) 60;
      border-style: solid;
      border-width: 5px;  background-repeat: no-repeat;
  }
}

.form-background {
  background-image: url('https://s3-ap-southeast-2.amazonaws.com/flayr-bucket-storage/beauty-product-flatlay_925x+(1).jpg');
  background-size: 100%;
  padding: 80px;
}

@media screen and (max-width: 600px) {
  .form-background {
      background-image: none;
      padding: 10px;
      margin-bottom: -100px;
  }
}
@media screen and (max-width: 600px) {

  .widget{
    margin-top: 100px;
  }

}

.button-form{
  margin-right: 20px;

}

.sub-option{
  margin-left: 10px;
}

.form-container{
  margin-top: 30px;
}


.form-field-error{
  border-color:#CB444A;
}

.loading-bar{
  padding-top: 50px;
  width: 200px;
  height: 200px;
  display:block;
}

.loading-text{
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;

  width: 50%;
  margin: 0 auto;
}

.prev-div {
  z-index: 0;
  cursor: pointer;
}

.pac-container {
  background-color:white;
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

.pac-item{
  padding: 5px;
}

.Button--primary{
  z-index: 0;
}

.date-input{
  position: relative;
  cursor: pointer;
  width: 100%;

}




.error-span{
  color: #CB444A;
}

.info-box {
  font-size: 80%;
  padding-left: 20px;
  padding-top: 10px;
}

.info-box-hidden {
  display: none;
  visibility: hidden;
}

.outer-container{
  padding-top: 40px;
  padding-bottom: 5px;

  text-align: center;
}


.inner-container{
  padding: 50px;
  font-size:22px;
  display: inline-block;
}


.small-profile-pic{
  border-top-left-radius: 100px; 
  border-top-right-radius: 100px; 
  border-bottom-right-radius: 100px; 
  border-bottom-left-radius: 100px; 

  height: 80%;
  width: 80%;
}

.big-profile-pic{
  object-fit: cover;
width: 350px;
height: 300px;
}

.spr-badge-caption{
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1300px){
  .big-profile-pic{
    
        object-fit: cover;
        width: 250px;
        height: 200px;
 
  }
}

@media screen and (max-width: 400px){
  .big-profile-pic{
    
        object-fit: cover;
        width: 250px;
        height: 180px;
 
  }
}


.show-location-link{
  margin-left:65px;
}

.loading-bar{
  width: 200px;
  height: 200px;
}

.loading-text{
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;

  width: 50%;
  margin: 0 auto;
}


@media screen and (min-width: 1008px) {
  .desktop-center{
      text-align: center;
  }
}



@media screen and (min-width: 1008px) {
  .desktop-inner{
      margin-left: -5%;
      display: inline-block;
  }
}


.message-box{
  text-align:center;
  margin-top: 20px;
}


.message-item{
  display: inline-block;
}

.faq-item{
  font-size: 90%;
  color: #6A6A6A;

}

.close-quote {
  cursor: pointer
}


.profile-blurb-text{
position:absolute;
bottom:0;
left:0;
right:0;
height:40px;
background:linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              #F7F7F7 100%)
}

.profile-box{
  margin-bottom: 30px;
  display: flex;
}

.profile-pic{
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.profile-image-box{
margin-left: 10px;
}

.reviews-data-box{
  margin-top: 5px;
  margin-left: 20px;
  flex: 1;
}

.label-background{
  background-color: white
}

input{
  font-size: 16px;
}
select{
  font-size: 16px;
}
@media screen and (max-width: 600px) {
.breadcrumbs {
  font-size: 0.8em;
  /* margin: 20px 11px; */
  padding-top: 5px;
  padding-left: 15px;
  padding-bottom: 0;
  /* color: darkgray; */
  background-color: #EFEFEF;
}
}
@media screen and (min-width: 600px) {
  .breadcrumbs {
    font-size: 0.8em;
    /* margin: 20px 11px; */
    padding: 5px;
    /* color: darkgray; */
    background-color: white;
  }
}
