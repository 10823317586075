@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.shopify-section--header {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 5
}

.Header {
  background: #fff;
  color: #1c1b1b;
  box-shadow: 0 -1px #ddd inset;
  -webkit-transition: background 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  -webkit-transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out
}


.Header .Heading,
.Header .Link--secondary,
.Header .Link--primary:hover {
    color: #1c1b1b
}

.Header .Text--subdued,
.Header .Link--primary,
.Header .Link--secondary:hover {
    color: #6a6a6a
}

@media screen and (min-width: 1240px) {
  .js .Header__Wrapper {
      opacity: 0;
      -webkit-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out
  }
  .Header:not(.Header--sidebar) .Header__Wrapper {
      padding: 18px 50px
  }
  .Header__CurrencySelector {
      display: inline-block
  }
  .Header:not(.Header--sidebar) .Header__FlexItem--fill {
      -webkit-flex: 1 0;
              flex: 1 0
  }
  .Header__FlexItem:last-child {
      text-align: right
  }
  .Header--inline .Header__FlexItem:first-child .Header__CurrencySelector {
      display: none
  }
  .Header--center .Header__Wrapper.Header__Wrapper {
      padding-bottom: 24px
  }
  .Header--center .Header__MainNav {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 18px;
      width: 100%;
      text-align: center
  }
  .Header--center .Header__MainNav .HorizontalList {
      margin-left: 0;
      margin-right: 0
  }
  .Header--center .Header__FlexItem {
      margin-bottom: 40px
  }
  .Header--center .Header__FlexItem:last-child .Header__CurrencySelector {
      display: none
  }
  .Header--initialized .Header__Wrapper {
      opacity: 1
  }
}

@media screen and (min-width: 641px) {
  .Header__Wrapper {
      padding: 18px 30px
  }
  .Header__LogoImage {
      max-width: 100%
  }
  .Header__Icon .Icon--nav-desktop {
      height: 17px;
      width: 24px
  }
  .Header__Icon .Icon--cart-desktop {
      height: 23px;
      width: 19px
  }
}

.Header__Wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 15px 18px
}

.Header__Logo {
  position: relative;
  margin-bottom: 0
}

.Header__LogoLink,
.Header__LogoImage {
  display: block
}

.Header__LogoImage {
  max-width: 90px;
  margin: 0 auto;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out
}

.supports-sticky .Header--transparent .Header__LogoImage--primary:not(:only-child) {
  opacity: 0
}

.supports-sticky .Header--transparent .Header__LogoImage--transparent {
  opacity: 1
}

.Header__LogoImage--transparent {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0
}

/* 
FLAYR CUSTOM START
*/

.ProductMeta__PriceList.Heading a .spr-badge .spr-badge-caption:after {
    content:" - View All";
  }
  
  .text-cycle {
    display: none;
    margin-top: 20px;
  }
  
  .alert-box {
    margin-top: 20px;
    color: #ff4d4d;
  }
  inp
  #trialBooking {
      display:none;
    }

  .col-no-padding {
    padding: 0px
  }
    
  .custom-select {
    height: 54px;
    width: 100%;
    border: 1px solid #cfcfcf;
    position:relative;
  }
  .custom-select:hover {
    cursor: pointer;
  }
  
  .custom-select select {
    border: none;
    background: none;
    font-size: 16px;
    height: 54px;
    width: 100%;
    padding: 15px;
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none;       /* remove default arrow */
  }
  
  .custom-select svg {
    position: absolute;
    top: 50%;
    right: 15px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  
  #shopify-product-reviews .spr-summary-starrating,
  .spr-starrating .spr-summary-starrating,
  .spr-summary-caption {
    display: block  !important;
  }
  
  /*  Removes write review  */
  
  .spr-summary-actions {
    margin-top: 10px;
  }
  
  .spr-summary-actions-newreview {
    visibility: hidden;
    display: none;
  }
  
  .spr-summary-actions-togglereviews br + div {
    display: none;
    visibility: hidden;
  }
  
  .ProductMeta {
    text-align: left;
  }
  
  .ProductMeta__PriceList.Heading {
    margin-top: 0;
  }
  
  .spr-badge {
    font-size: 85%;
  }
  
  .spr-icon {
    color: #000000 !important;
  }
  
  #shopify-product-reviews .spr-pagination {
    display: block;
  }
  
  .spr-pagination-prev {
    display: none !important;
  }
    
  .Rte a:not(.Button) {
    text-decoration: none;
  }
  
  #quotenotification {
    background: #000000;
    color: #ffffff;
  }
  
  
  #quotenotification-red {
    background: #ff4d4d;
    color: #ffffff;
  }
  
  #quotenotification-green {
    background: #8cf2d9 ;
    color: #000000;
  }
  
  
  .quoteLine {
    display: block;
    overflow: hidden;
  }
  
  .quoteLine + hr {
    margin-top: 32px;
  }
  
  .quoteLine .quotePerson {
    display: inline-block;
    font-weight: 700;
    width: 200px;
  }
  
  .quoteLine .quoteService {
    display: inline-block;
    font-weight: 400;
    width: auto;
  }
  
  hr {
    border-top: 1px solid #cfcfcf;
  }
  
  .CollectionMain_Offers {
    margin-bottom: 20px;
  }
  
  .offer-item {
    /* margin-bottom: 6px; */
    /* rm override - compensated for this margin in the Grid cell white */
    /* border-bottom: 1px solid #DDDDDD; */
    line-height: 1.3;
  }
  
  .checkbox::after{
    background-color: black
  }

  .checkbox::before{
    background-color: white
  }

  .Form__Checkbox:checked ~ label::before {
    border-color: #1c1b1b;
    background-color: #388288;
  }
  .offer-stylist-photo {
    padding-left: 0 !important;
    margin-bottom: 0!important;
  }
  
  .offer-stylist-details {
    padding-left: 10px !important;
    margin-bottom: 0!important;
    text-align: left;
  }
  
  .offer-stylist-details .ProductItem__Title.Heading {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
  }
  
  
  .offer-feature-image {
    position: relative;
    /* margin-bottom: 10px; */
    margin-bottom: 0px;
  }
  
  .offer-feature-image-price {
    display: inline-block;
    font-size: 12px;
    padding: 2px 6px;
    background: #000000;
    color: #ffffff;
    position: absolute;
    bottom: 1px;
    right: 0;
  }
  
  .offer-image-stylist-overlay {
    background: rgba(255,255,255,.75);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 4px;
    color: black;
  }
  
  .offer-image-stylist-overlay .Grid {
    margin: 0 !important;
  }
  
  .ProductItem__Title {
    margin-bottom: 0;
  }
  
  .spr-badge-caption {
    display: block;
    color: black;
  }
  
  
  
  /* 
  FLAYR MEDIA QUERIES MIXINS
  */
  
  /* SMALL ONLY */
  @media only screen and (max-width: 749px) {
    
    .clients {
        text-align: center;
    }
    
    .clients img {
      width: 55%;
      display: block;
      margin: 0 auto 10px auto;
    }
    
    .in-progress {
      text-align: center;
    }
    
    .loader {
      margin: 20px auto 20px auto;
    }
  
    .ProductMeta__Description--inner {
      padding: 0 24px;
    }
  
    .quoteLine {
      display: block;
      overflow: hidden;
    }
  
    .quoteLine .quotePerson {
      width: 50%;
      float: left;
      clear: right;
    }
  
    .quoteLine .quoteService {
      width: 50%;
      float: left;
    }
    
    .Timeline__ListItem {
        height: 450px;
    }
  
  
    .Container_Offers {
      padding: 0 24px;
    }
  
  }
  
  .Collapsible.Collapsible--large + #offers {
    margin-top: 40px;
  }
  
  /* MEDIUM UP */
  @media only screen and (min-width: 750px) {
    .ProductMeta__Description {
      border-top: 0;
    }
  }
  
  @media only screen and (min-width: 1240px) {
    .offer-image-stylist-overlay {
      padding: 10px;
    }
    
    .offer-stylist-details {
      margin-top: 5%;
    }
  
  }
  
  /* Get A Quote */
    .getaquote {
      background: black;
        background-color: black;
      color: white;
    
    }
    
    .getaquote .Section__Heading {
        color: white;
      
    }
    
    .getaquote .Button {
        color: #363636;
      border-color: #ffffff;
    }
    
    .getaquote .Button::before {
      background-color: white;
      color: #363636;
    }
  
  
  /* Override of styling for Makeup Artist Grid cards */
  .subdued--heading {
      text-transform: none;
        letter-spacing: 0;
        font-size: 0.8em
  }
  
  
  /* Shrink button spacing - mainly used for header */
  .Button--compressed {
      padding: 6px 7px;
        letter-spacing: 0;
        line-height: 1.5;
    text-transform: none;
  /*   	font-size: 0.6em */
  }
  
  /* Shrink button spacing - mainly used for header */
  .Button--thin {
    padding: 6px 24px;
}
  
  .Button--white{
      background-color: white;
  }
  
  
  
  .ArticleToolbar--red {
      background: #ff4d4d;
  }
  
  .Heading--white {
      color: white;
  }
  
  /* Extra Spacing for header */
  @media only screen and (max-width: 1000px) {
  .stylist-page-navbar-space {
    margin-top: 8em;
  }
}

/* RM - edit to make grid suit mobile layout for presenting stylist profile cards */
.Grid {
  margin-left: -12px;
  margin-right: -12px;
  overflow: hidden;
}

.Grid__Cell--12 {
  padding-left: 0px; 
}

.Grid__Cell--TopPad5 {
  padding-top: 4px; 
}

.Grid__Cell--white {
  background-color: #ffffff7a;
  margin-bottom: 6px;
  padding-left: 12px;
}

.Offer__PricingCard {
  padding-top: 0px;
  font-size: 0.85rem;
  color: black;
  border-top: 0;
  margin-top: 10px;
  margin-right: 33px;
}

.ViewProfile--rightbottom{
  /* font-size: 1.2rem; */
  /* color: #757575; */
  position: absolute;
  bottom: 3px;
  right: 3px;
  /* font-size: 1rem; */
  /* border: none; */
}

.small-profile-pic-fixedheight {
  /* border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px; */
  border-radius: 50%;
  height: 88px;
  width: 88px;
}

.Alert__Booked {
  color: #ff4d4d;
}

.Alert__Green{
  color: #339999;
  font-size: 0.8em;
}

.Alert__Stats{
  color: #ff4d4d;
  /* font-size: 0.8em; */
  
}

.Alert__Stats--cycling{
  -webkit-animation:fading 5s infinite;
          animation:fading 5s infinite}@-webkit-keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}
}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}
}

.instant-book-subtext{
  opacity: 0.7;
}

.material-icons {
  font-size: inherit;
}

.Viewprofile__arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #757575;
  font-size: 2rem;
  right: -5px;
  /* font-weight: 200; */
}

.Card-bookedout {
  opacity: 0.33;
}

.Label__Great-Value {
  position: absolute;
  left: 0px;
  top: 2px;
  text-align: left;
}

.Label__Great-Value--bottomright {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: #008489;
  letter-spacing: 0;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}
.reviewitem{
  height:500px;
}

.Label__Instant__Book {
  color: #008489;
  letter-spacing: 0;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}

.review-container{
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 1%;
  padding-right:1%;
  font-size:22px;
}

.dotsClass{
  background-color: black;
}
.form-box {
  padding: 30px;
  width: 30%;
  margin-left: 10%;
  background-color: white;
  border-color: linear-gradient(#25a9e0, #1b75bb,#2e3191, #7f3f97);
  -webkit-border-image: -webkit-linear-gradient(left, #25a9e0, #1b75bb,#2e3191, #7f3f97) 60;
          border-image: linear-gradient(0.25turn, #25a9e0, #1b75bb,#2e3191, #7f3f97) 60;
  border-style: solid;
  border-width: 5px;  background-repeat: no-repeat;
  
}

.footer-link{
  color: #6c757d;
}

@media screen and (max-width: 2500px) {
  .form-box {
      padding: 30px;
      width: 35%;
      margin-left: 7%;
      background-color: white;
    
  }
}

@media screen and (max-width: 1400px) {
  .form-box {
      padding: 30px;
      width: 60%;
      margin-left: 0;
      background-color: white;
      

  }
}


@media screen and (max-width: 1020px) {
  .form-box {
      padding: 30px;
      width: 90%;
      margin-left: 0;
      background-color: white;
     

  }
}

@media screen and (max-width: 800px) {
  .form-box {
      padding: 30px;
      width: 80%;
      margin-left: 0;
      background-color: white;
   

  }
}

@media screen and (max-width: 600px) {
  .form-box {
      width: 100%;
      margin-left: 0;
      background-color: white;
      border-color: linear-gradient(#25a9e0, #1b75bb,#2e3191, #7f3f97);
      -webkit-border-image: -webkit-linear-gradient(left, #25a9e0, #1b75bb,#2e3191, #7f3f97) 60;
              border-image: linear-gradient(0.25turn, #25a9e0, #1b75bb,#2e3191, #7f3f97) 60;
      border-style: solid;
      border-width: 5px;  background-repeat: no-repeat;
  }
}

.form-background {
  background-image: url('https://s3-ap-southeast-2.amazonaws.com/flayr-bucket-storage/beauty-product-flatlay_925x+(1).jpg');
  background-size: 100%;
  padding: 80px;
}

@media screen and (max-width: 600px) {
  .form-background {
      background-image: none;
      padding: 10px;
      margin-bottom: -100px;
  }
}
@media screen and (max-width: 600px) {

  .widget{
    margin-top: 100px;
  }

}

.button-form{
  margin-right: 20px;

}

.sub-option{
  margin-left: 10px;
}

.form-container{
  margin-top: 30px;
}


.form-field-error{
  border-color:#CB444A;
}

.loading-bar{
  padding-top: 50px;
  width: 200px;
  height: 200px;
  display:block;
}

.loading-text{
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;

  width: 50%;
  margin: 0 auto;
}

.prev-div {
  z-index: 0;
  cursor: pointer;
}

.pac-container {
  background-color:white;
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

.pac-item{
  padding: 5px;
}

.Button--primary{
  z-index: 0;
}

.date-input{
  position: relative;
  cursor: pointer;
  width: 100%;

}




.error-span{
  color: #CB444A;
}

.info-box {
  font-size: 80%;
  padding-left: 20px;
  padding-top: 10px;
}

.info-box-hidden {
  display: none;
  visibility: hidden;
}

.outer-container{
  padding-top: 40px;
  padding-bottom: 5px;

  text-align: center;
}


.inner-container{
  padding: 50px;
  font-size:22px;
  display: inline-block;
}


.small-profile-pic{
  border-top-left-radius: 100px; 
  border-top-right-radius: 100px; 
  border-bottom-right-radius: 100px; 
  border-bottom-left-radius: 100px; 

  height: 80%;
  width: 80%;
}

.big-profile-pic{
  object-fit: cover;
width: 350px;
height: 300px;
}

.spr-badge-caption{
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1300px){
  .big-profile-pic{
    
        object-fit: cover;
        width: 250px;
        height: 200px;
 
  }
}

@media screen and (max-width: 400px){
  .big-profile-pic{
    
        object-fit: cover;
        width: 250px;
        height: 180px;
 
  }
}


.show-location-link{
  margin-left:65px;
}

.loading-bar{
  width: 200px;
  height: 200px;
}

.loading-text{
  padding-top: 5%;
  padding-left: 5%;
  padding-right: 5%;

  width: 50%;
  margin: 0 auto;
}


@media screen and (min-width: 1008px) {
  .desktop-center{
      text-align: center;
  }
}



@media screen and (min-width: 1008px) {
  .desktop-inner{
      margin-left: -5%;
      display: inline-block;
  }
}


.message-box{
  text-align:center;
  margin-top: 20px;
}


.message-item{
  display: inline-block;
}

.faq-item{
  font-size: 90%;
  color: #6A6A6A;

}

.close-quote {
  cursor: pointer
}


.profile-blurb-text{
position:absolute;
bottom:0;
left:0;
right:0;
height:40px;
background:-webkit-linear-gradient(
              top,
              rgba(255, 255, 255, 0) 0%,
              #F7F7F7 100%);
background:linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              #F7F7F7 100%)
}

.profile-box{
  margin-bottom: 30px;
  display: -webkit-flex;
  display: flex;
}

.profile-pic{
  border-radius: 50%;
  width: 70px;
  height: 70px;
}

.profile-image-box{
margin-left: 10px;
}

.reviews-data-box{
  margin-top: 5px;
  margin-left: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.label-background{
  background-color: white
}

input{
  font-size: 16px;
}
select{
  font-size: 16px;
}
@media screen and (max-width: 600px) {
.breadcrumbs {
  font-size: 0.8em;
  /* margin: 20px 11px; */
  padding-top: 5px;
  padding-left: 15px;
  padding-bottom: 0;
  /* color: darkgray; */
  background-color: #EFEFEF;
}
}
@media screen and (min-width: 600px) {
  .breadcrumbs {
    font-size: 0.8em;
    /* margin: 20px 11px; */
    padding: 5px;
    /* color: darkgray; */
    background-color: white;
  }
}

@-webkit-keyframes fade-in {
  0% {
      opacity: 0;
      visibility: hidden
  }
  100% {
      opacity: 1;
      visibility: visible
  }
}

@keyframes fade-in {
  0% {
      opacity: 0;
      visibility: hidden
  }
  100% {
      opacity: 1;
      visibility: visible
  }
}

@-webkit-keyframes fade-out {
  0% {
      opacity: 1;
      visibility: visible
  }
  100% {
      opacity: 0;
      visibility: hidden
  }
}

@keyframes fade-out {
  0% {
      opacity: 1;
      visibility: visible
  }
  100% {
      opacity: 0;
      visibility: hidden
  }
}

@-webkit-keyframes rotate {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}

@keyframes rotate {
  0% {
      -webkit-transform: rotate(0);
      transform: rotate(0)
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
  }
}

@-webkit-keyframes draw-stroke {
  100% {
      stroke-dashoffset: 0
  }
}

@keyframes draw-stroke {
  100% {
      stroke-dashoffset: 0
  }
}

@-webkit-keyframes checkmark-fill {
  100% {
      box-shadow: inset 0px 0px 0px 30px #1c1b1b
  }
}

@keyframes checkmark-fill {
  100% {
      box-shadow: inset 0px 0px 0px 30px #1c1b1b
  }
}

@-webkit-keyframes scale-up-bounce {
  0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }
  40% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
  }
  100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes scale-up-bounce {
  0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }
  40% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1)
  }
  100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@-webkit-keyframes scale-up-bounce-spring {
  0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }
  20% {
      -webkit-transform: scale(1.15);
      transform: scale(1.15)
  }
  50% {
      opacity: 1;
      -webkit-transform: scale(0.95);
      transform: scale(0.95)
  }
  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes scale-up-bounce-spring {
  0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }
  20% {
      -webkit-transform: scale(1.15);
      transform: scale(1.15)
  }
  50% {
      opacity: 1;
      -webkit-transform: scale(0.95);
      transform: scale(0.95)
  }
  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@-webkit-keyframes scale-down-bounce {
  0% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
  }
  20% {
      -webkit-transform: scale(1.05);
      transform: scale(1.05)
  }
  100% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }
}

@keyframes scale-down-bounce {
  0% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1)
  }
  20% {
      -webkit-transform: scale(1.05);
      transform: scale(1.05)
  }
  100% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5)
  }
}

@-webkit-keyframes scale-up {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0)
  }
  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@keyframes scale-up {
  0% {
      -webkit-transform: scale(0);
      transform: scale(0)
  }
  100% {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

@-webkit-keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgba(28, 27, 27, 0.5)
  }
  50% {
      box-shadow: 0 0 0 15px rgba(28, 27, 27, 0)
  }
}

@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgba(28, 27, 27, 0.5)
  }
  50% {
      box-shadow: 0 0 0 15px rgba(28, 27, 27, 0)
  }
}

@-webkit-keyframes wobble {
  0%,
  100% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  50% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
}

@keyframes wobble {
  0%,
  100% {
      -webkit-transform: rotate(10deg);
      transform: rotate(10deg)
  }
  50% {
      -webkit-transform: rotate(-10deg);
      transform: rotate(-10deg)
  }
}

@-webkit-keyframes modal-open {
  0% {
      opacity: 0;
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      visibility: visible
  }
}

@keyframes modal-open {
  0% {
      opacity: 0;
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      visibility: visible
  }
}

@-webkit-keyframes modal-close {
  0% {
      opacity: 1;
      visibility: visible
  }
  100% {
      opacity: 0;
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
  }
}

@keyframes modal-close {
  0% {
      opacity: 1;
      visibility: visible
  }
  100% {
      opacity: 0;
      -webkit-transform: translateY(20%);
      transform: translateY(20%)
  }
}

@media (max-width: 999px) {
  .quirks-fix--ios-instagram {
      height: auto;
      min-height: 100%;
      padding-bottom: 250px
  }
}

html,
body {
  margin: 0;
  width: 100%;
  height: 100%
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  overflow-x: hidden
}

iframe {
  margin: 0;
  padding: 0;
  border: 0
}

b,
strong {
  font-weight: 500
}

small {
  font-size: .875em
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  line-height: 1em
}

sub,
sup {
  font-size: .75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -0.5em
}

sub {
  bottom: -0.25em
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none
}

img {
  border: 0;
  max-width: 100%
}

figure {
  margin: 0
}

hr {
  box-sizing: content-box;
  height: 0
}

form {
  margin: 0
}
s
button,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-font-smoothing: inherit;
  border: none;
  background: transparent;
  line-height: normal
}

button {
  overflow: visible
}

button,
select {
  text-transform: none
}


fieldset {
  margin: 0;
  padding: 0;
  border: 0
}

legend {
  border: 0;
  padding: 0
}

textarea {
  overflow: auto
}

select::-ms-expand {
  display: none
}

optgroup {
  font-weight: bold
}

option {
  background-color: #fff;
  color: #000
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em
}

td,
th {
  padding: 0;
  font-weight: normal
}

body {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  line-height: 1.3em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -webkit-font-smoothing: subpixel-antialiased
}

a,
.link {
  text-decoration: none;
  color: #1c1b1b;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out
}

a:hover,
.link:hover {
  color: #0a0909
}

.link--muted {
  color: inherit;
  text-decoration: underline
}

h1,
.heading-1 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 1.71429em;
  line-height: 1.3em
}

@media (min-width: 750px) {
  h1,
  .heading-1 {
      font-size: 2em
  }
}

h2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 1.28571em;
  line-height: 1.3em
}

.main h2 {
  color: #333333
}

.sidebar h2 {
  color: #303030
}

.content-box h2 {
  color: #333333
}

.default-background h2 {
  color: #333333
}

h3 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.3em;
  margin-bottom: 0.57143em
}

.main h3 {
  color: #333333
}

.sidebar h3 {
  color: #303030
}

.content-box h3 {
  color: #333333
}

.default-background h3 {
  color: #333333
}

h3:not(:first-child) {
  margin-top: 1.5em
}

p {
  margin: 0;
  line-height: 1.5em
}

p+p {
  margin-top: 1.5em
}

.emphasis {
  font-weight: 500
}

.main .emphasis {
  color: #333333
}

.sidebar .emphasis {
  color: #303030
}

.content-box .emphasis {
  color: #333333
}

.default-background .emphasis {
  color: #333333
}

.main .small-text .emphasis {
  color: #545454
}

.sidebar .small-text .emphasis {
  color: #4f4f4f
}

.content-box .small-text .emphasis {
  color: #545454
}

.default-background .small-text .emphasis {
  color: #545454
}

.small-text {
  font-size: 0.85714em
}

.main .small-text {
  color: #737373
}

.sidebar .small-text {
  color: #6c6c6c
}

.content-box .small-text {
  color: #737373
}

.default-background .small-text {
  color: #737373
}

.address {
  font-style: normal;
  line-height: 1.5em
}

.address--tight {
  line-height: inherit
}

.layout-flex {
  -webkit-align-items: baseline;
  align-items: baseline;
  display: -webkit-flex;
  display: flex;
  margin: -0.28571rem
}

.layout-flex--wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap
}

.layout-flex--tight-vertical {
  margin-bottom: -0.14286rem;
  margin-top: -0.14286rem
}

.layout-flex--tight-vertical .layout-flex__item {
  padding-bottom: 0.14286rem;
  padding-top: 0.14286rem
}

.layout-flex--loose-horizontal {
  margin-left: -0.57143rem;
  margin-right: -0.57143rem
}

.layout-flex--loose-horizontal .layout-flex__item {
  padding-left: 0.57143rem;
  padding-right: 0.57143rem
}

.layout-flex__item {
  padding: 0.14286rem
}

.layout-flex__item--stretch {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto
}

.icon-svg {
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out
}

.icon-symbols {
  display: none
}

.icon-svg--block {
  display: block
}

.icon-svg--color-accent {
  color: #1c1b1b;
  fill: currentColor
}

.icon-svg--color-black {
  color: #000;
  fill: currentColor
}

.icon-svg--color-blue {
  color: #1990c6;
  fill: currentColor
}

.icon-svg--color-gray {
  color: #9b9b9b;
  fill: currentColor
}

.icon-svg--color-gray-dark {
  color: #595959;
  fill: currentColor
}

.icon-svg--color-gray-light {
  color: #959595;
  fill: currentColor
}

.icon-svg--color-red {
  color: #ce4549;
  fill: currentColor
}

.icon-svg--color-white {
  color: #fff;
  fill: currentColor
}

.icon-svg--color-yellow {
  color: #e7a321;
  fill: currentColor
}

.main .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.sidebar .icon-svg--color-adaptive-lighter {
  color: #888888;
  fill: currentColor
}

.content-box .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.default-background .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.btn .icon-svg--color-adaptive-lighter {
  color: #a4a4a4;
  fill: currentColor
}

.main .field__icon .icon-svg--color-adaptive-lighter,
.main .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.sidebar .field__icon .icon-svg--color-adaptive-lighter,
.sidebar .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.content-box .field__icon .icon-svg--color-adaptive-lighter,
.content-box .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.default-background .field__icon .icon-svg--color-adaptive-lighter,
.default-background .field__caret .icon-svg--color-adaptive-lighter {
  color: #919191;
  fill: currentColor
}

.main .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.sidebar .icon-svg--color-adaptive-light {
  color: #6c6c6c;
  fill: currentColor
}

.content-box .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.default-background .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.btn .icon-svg--color-adaptive-light {
  color: #a4a4a4;
  fill: currentColor
}

.main .field__icon .icon-svg--color-adaptive-light,
.main .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.sidebar .field__icon .icon-svg--color-adaptive-light,
.sidebar .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.content-box .field__icon .icon-svg--color-adaptive-light,
.content-box .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.default-background .field__icon .icon-svg--color-adaptive-light,
.default-background .field__caret .icon-svg--color-adaptive-light {
  color: #737373;
  fill: currentColor
}

.icon-svg--size-10 {
  width: 10px;
  height: 10px
}

.icon-svg--size-12 {
  width: 12px;
  height: 12px
}

.icon-svg--size-14 {
  width: 14px;
  height: 14px
}

.icon-svg--size-16 {
  width: 16px;
  height: 16px
}

.icon-svg--size-18 {
  width: 18px;
  height: 18px
}

.icon-svg--size-24 {
  width: 24px;
  height: 24px
}

.icon-svg--size-32 {
  width: 32px;
  height: 32px
}

.icon-svg--size-48 {
  width: 48px;
  height: 48px
}

.icon-svg--size-64 {
  width: 64px;
  height: 64px
}

.icon-svg--size-184 {
  width: 184px;
  height: 184px
}

.icon-svg--align-text-bottom {
  vertical-align: text-bottom
}

.icon-svg--inline-before {
  margin-right: 0.5em
}

.icon-svg--inline-after {
  margin-left: 0.5em
}

.icon-svg--flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1)
}

.icon-svg--flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1)
}

.icon {
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block
}

.icon-background--payment-gateways {
  fill: #d9d9d9
}

.icon-svg--spinner-button {
  width: 20px;
  height: 20px
}

.icon--card-fields-error {
  width: 67px;
  height: 67px;
  background-image: url(//cdn.shopify.com/s/assets/checkout/card-fields-error-small-71df2c50ea091ceba072c8762606ceb6fc2c7867db1981ef8433c7af03faed9e.png);
  background-image: url(//cdn.shopify.com/s/assets/checkout/card-fields-error-small-625b9e91662927e93a8d6b129812bafde0518615ed7bbc780ce869cba95237fd.svg), none
}

@media (min-width: 750px) {
  .icon--card-fields-error {
      width: 88px;
      height: 88px;
      background-image: url(//cdn.shopify.com/s/assets/checkout/card-fields-error-e95686dee428fc88658b7f99bfde129494ee06cf10e3f5b7b34f259d32eb02d5.png);
      background-image: url(//cdn.shopify.com/s/assets/checkout/card-fields-error-66d2404939ee1ee3d3a9bdb1f77aab63f5bcb95833a666e4d328f3a51d9df6c3.svg), none
  }
}

.icon--free-tag {
  width: 94px;
  height: 46px;
  background-image: url(//cdn.shopify.com/s/assets/checkout/free-tag-small-b3e7c43f00262c24a10f1a90f88e648232cace43aa4d242a3bc3379ea478f7e1.png);
  background-image: url(//cdn.shopify.com/s/assets/checkout/free-tag-small-5439598929990a936b18a6bf65080248b85b7ae7eca0e37b8b365b6b5138db38.svg), none
}

@media (min-width: 750px) {
  .icon--free-tag {
      width: 152px;
      height: 80px;
      background-image: url(//cdn.shopify.com/s/assets/checkout/free-tag-cfac1b87d84c1b6fe0570e5942f93e8348f4d1980c27abdfd7e193b08d22be1d.png);
      background-image: url(//cdn.shopify.com/s/assets/checkout/free-tag-c2dd504ac09e85e0958c656eb3b00e9bedc4fbaeaaa6031c81b045674cc6b7eb.svg), none
  }
}

.icon--shopify-pay-primary {
  width: 85px;
  height: 17px
}

.icon--shopify-pay {
  width: 35px;
  height: 15px
}

.icon--shopify-pay--dark {
  fill: #000
}

.icon--shopify-pay--light {
  fill: #fff
}

.icon--gift-card {
  width: 16px;
  height: 16px;
  background-image: url(//cdn.shopify.com/s/assets/checkout/gift-card-90f6141a5cfa6cbb477d88cf1835e0c3b1e76122d4e36d1e373de0b954e87b59.png);
  background-image: url(//cdn.shopify.com/s/assets/checkout/gift-card-a262d4faf79983ea2372d68228388158c727c7b71b918807c0d8c49eedc632b9.svg), none
}

.icon--offsite {
  width: 102px;
  height: 52px;
  background-image: url(//cdn.shopify.com/s/assets/checkout/offsite-small-594d944a8428e634d5111579612bbc4a149cded13b9097e4ead442e82b6b6331.png);
  background-image: url(//cdn.shopify.com/s/assets/checkout/offsite-small-0d8fd34431b27358e5c54a5ad660f76f8d628fad88320450d799287a8654982f.svg), none
}

@media (min-width: 750px) {
  .icon--offsite {
      width: 163px;
      height: 81px;
      background-image: url(//cdn.shopify.com/s/assets/checkout/offsite-8cec89458c6a416094bb72f0e2edff4735c4daaf21689fd3a18cd682ee214d9a.png);
      background-image: url(//cdn.shopify.com/s/assets/checkout/offsite-908d79d8d532f6af67d7cc99244ede733729c29379c349ee015fbcea71fd8274.svg), none
  }
}

.icon-svg--re-order {
  width: 20px;
  height: 16px
}

.icon-svg--spinner {
  -webkit-animation: fade-in 0.5s ease-in-out, rotate 0.5s linear infinite;
  animation: fade-in 0.5s ease-in-out, rotate 0.5s linear infinite
}

.ie .icon-svg--double-spinner,
.edge .icon-svg--double-spinner {
  -webkit-animation: rotate 0.5s linear infinite;
  animation: rotate 0.5s linear infinite
}

.ie .icon-svg--double-spinner .icon-svg--double-spinner__outer-circle,
.edge .icon-svg--double-spinner .icon-svg--double-spinner__outer-circle {
  display: none
}

.ie .icon-svg--double-spinner .icon-svg--double-spinner__inner-circle,
.edge .icon-svg--double-spinner .icon-svg--double-spinner__inner-circle {
  -webkit-animation: none;
  animation: none
}

.icon-svg--double-spinner__outer-circle {
  opacity: 0.3;
  animation: rotate 1s linear infinite reverse;
  -webkit-transform-origin: center;
  transform-origin: center
}

.icon-svg--double-spinner__inner-circle {
  -webkit-animation: rotate 0.5s linear infinite;
  animation: rotate 0.5s linear infinite;
  -webkit-transform-origin: center;
  transform-origin: center
}

.logo {
  display: block;
  vertical-align: middle
}

.logo__text {
  color: #333333
}

.logo__image {
  display: inline-block
}

.logo--right {
  text-align: right
}

.logo--center {
  text-align: center
}

.logo__image--small {
  max-height: 2.14286em
}

.logo__image--medium {
  max-height: 2.85714em
}

.logo__image--large {
  max-height: 3.57143em
}

@media (min-width: 1000px) {
  .logo__image--small {
      max-height: 2.85714em
  }
  .logo__image--medium {
      max-height: 4.28571em
  }
  .logo__image--large {
      max-height: 5.71429em
  }
}

body {
  color: #545454;
  background: white
}

.content {
  overflow: hidden
}

.wrap {
  display: block;
  margin: 0 auto;
  max-width: 40em;
  zoom: 1
}

.wrap:after,
.wrap:before {
  content: "";
  display: table
}

.wrap:after {
  clear: both
}

@media (max-width: 999px) {
  .wrap {
      width: 100%;
      box-sizing: border-box;
      padding: 0 1em
  }
}

@media (min-width: 1000px) {
  .wrap {
      padding: 0 5%;
      width: 90%;
      max-width: 78.57143em
  }
}

@media (max-width: 999px) {
  .no-js .content .wrap {
      display: table
  }
  .no-js .content .wrap .main {
      display: table-footer-group
  }
  .no-js .content .wrap .sidebar {
      display: table-header-group
  }
  .no-js .content .wrap .sidebar::after {
      display: none
  }
}

@media (min-width: 1000px) {
  .main {
      width: 52%;
      width: 52%;
      padding-right: 6%;
      float: left
  }
}

.main .logo {
  display: none
}

@media (min-width: 1000px) {
  .page--logo-main .main .logo {
      display: block
  }
}

.main__emphasis {
  font-weight: 500;
  color: #333333
}

.main__small-text {
  font-size: 0.85714em;
  color: #737373
}

.sidebar {
  position: relative;
  color: #4f4f4f
}

.no-js .sidebar,
.no-generatedcontent .sidebar {
  background: #efefef
}

@media (min-width: 1000px) {
  .sidebar {
      width: 38%;
      padding-left: 4%;
      background-position: left top;
      float: right
  }
}

.sidebar::after {
  content: "";
  display: block;
  width: 300%;
  position: absolute;
  top: 0;
  left: -100%;
  bottom: 0;
  background: #efefef;
  z-index: -1;
  box-shadow: 0 -1px 0 #d7d7d7 inset
}

@media (min-width: 1000px) {
  .sidebar::after {
      left: 0;
      background-position: left top;
      box-shadow: 1px 0 0 #d7d7d7 inset
  }
}

.sidebar__header {
  margin-bottom: 2.5em;
  display: none
}

@media (min-width: 1000px) {
  .page--logo-sidebar .sidebar__header {
      display: block
  }
}

.anyflexbox body,
.anyflexbox .content,
.anyflexbox .content .wrap,
.anyflexbox .main {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto
}

.anyflexbox .content .wrap {
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse
}

.anyflexbox .main__content {
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto
}

.anyflexbox .banner {
  -webkit-flex-shrink: 0;
          flex-shrink: 0
}

@media (min-width: 1000px) {
  .anyflexbox .content .wrap {
      -webkit-flex-direction: row;
      flex-direction: row
  }
}

@media (max-width: 749px) {
  .main {
      padding-top: 1.5em
  }
  .main__content {
      padding-bottom: 1.5em
  }
}

@media (min-width: 750px) {
  .main {
      padding-top: 1.5em
  }
  .main__content {
      padding-bottom: 4em
  }
}

@media (min-width: 1000px) {
  .page--no-banner .main,
  .page--no-banner .sidebar {
      padding-top: 4em
  }
  .page--banner .main,
  .page--banner .sidebar {
      padding-top: 2em
  }
  .main__header {
      padding-bottom: 2em
  }
  .sidebar__logo {
      margin-bottom: 2em
  }
  .page--logo-main .breadcrumb {
      margin-top: 1em
  }
}

.page--hidden-breadcrumbs .breadcrumb,
.page--hidden-main-header .main__header {
  display: none
}

table {
  width: 100%
}

td,
th {
  padding-left: 1em
}

td:first-child,
th:first-child {
  padding-left: 0;
  text-align: left
}

td:last-child,
th:last-child {
  text-align: right
}

.banner {
  padding: 1.5em 0;
  background: transparent;
  background-size: cover;
  display: none
}

.page--logo-sidebar .banner {
  background: transparent
}

@media (max-width: 999px) {
  .banner {
      display: block
  }
}

.page--banner .banner {
  display: block
}

@media (min-width: 1000px) {
  .page--banner .banner {
      padding-top: 6em;
      padding-bottom: 2em
  }
}

.btn {
  cursor: pointer;
  display: inline-block;
  background-color: #1c1b1b;
  background-clip: border-box;
  box-sizing: border-box;
  border: 1px transparent solid;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding: 1.4em 1.7em;
  text-align: center;
  position: relative;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out
}

.btn:hover {
  background-color: #020202;
  color: white
}

.btn:active {
  outline: none
}
.btn--size-small {
  padding: 0.92857em
}

.btn--subdued {
  background-color: transparent;
  border-color: rgba(179, 179, 179, 0.5);
  color: #1c1b1b;
  font-weight: normal
}

.btn--subdued:hover {
  background-color: rgba(28, 27, 27, 0.06);
  color: #0a0909
}

.btn--subdued:focus,
.btn--subdued:active {
  border-color: #020202;
  box-shadow: 0 0 0 1px #020202 inset
}

.btn--loading {
  pointer-events: none;
  cursor: default
}

.btn--loading .btn__content,
.btn--loading .btn__icon {
  opacity: 0
}

.btn--loading .btn__spinner {
  -webkit-animation: rotate 0.5s linear infinite;
  animation: rotate 0.5s linear infinite;
  opacity: 1
}

.js .main .btn--disabled {
  cursor: default;
  background: #cccccc;
  box-shadow: none
}

.js .sidebar .btn--disabled {
  cursor: default;
  background: #bfbfbf;
  box-shadow: none
}

.js .content-box .btn--disabled {
  cursor: default;
  background: #cccccc;
  box-shadow: none
}

.js .default-background .btn--disabled {
  cursor: default;
  background: #cccccc;
  box-shadow: none
}

.btn__content {
  position: relative;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out
}

.btn__icon {
  margin-top: -0.28571em;
  margin-bottom: -0.07143em
}

.btn__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  opacity: 0
}

.step__footer {
  z-index: 2;
  position: relative;
  margin-top: 1em
}

.no-js .step__footer,
.no-anyflexbox .step__footer {
  zoom: 1
}

.no-js .step__footer:after,
.no-js .step__footer:before,
.no-anyflexbox .step__footer:after,
.no-anyflexbox .step__footer:before {
  content: "";
  display: table
}

.no-js .step__footer:after,
.no-anyflexbox .step__footer:after {
  clear: both
}

@media (min-width: 750px) {
  .step__footer {
      margin-top: 1.5em
  }
}

.step__footer__previous-link-content {
  vertical-align: middle
}

.step__footer__previous-link,
.step__footer__info {
  display: block
}

@media (max-width: 749px) {
  .step__footer__previous-link,
  .step__footer__info {
      padding-top: 1.5em;
      text-align: center
  }
}

@media (min-width: 750px) {
  .step__footer__previous-link,
  .step__footer__info {
      float: left
  }
}

.step__footer__info {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center
}

@media (max-width: 749px) {
  .step__footer__info {
      -webkit-justify-content: center;
      justify-content: center
  }
}

.previous-link__icon {
  -webkit-transition: all 0.2s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.2s cubic-bezier(0.3, 0, 0, 1);
  margin-right: 0.25em
}

.step__footer__previous-link:hover .previous-link__icon {
  fill: #0a0909;
  -webkit-transform: translateX(-5px) "";
  transform: translateX(-5px) ""
}

@media (min-width: 750px) {
  .step__footer__continue-btn {
      float: right
  }
}

@media (min-width: 750px) {
  .anyflexbox .step__footer {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row-reverse;
      flex-direction: row-reverse;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: space-between;
      justify-content: space-between
  }
  .anyflexbox .step__footer__previous-link,
  .anyflexbox .step__footer__info {
      margin-right: 1em
  }
}

.edit-link {
  display: block;
  margin-top: 0.75em
}

.notice+.step__sections {
  margin-top: 1.5em
}

@media (min-width: 750px) {
  .notice+.step__sections {
      margin-top: 2.5em
  }
}

.step__sections+form {
  padding-top: 2em
}

@media (min-width: 1000px) {
  .step__sections+form {
      padding-top: 0
  }
}

.section {
  position: relative;
  padding-top: 2em
}

@media (min-width: 750px) {
  .section {
      padding-top: 3em
  }
}

.section:first-child {
  padding-top: 0
}

.section--half-spacing-top,
.section--half-spacing-bottom+.section {
  padding-top: 1em
}

@media (min-width: 750px) {
  .section--half-spacing-top,
  .section--half-spacing-bottom+.section {
      padding-top: 1.5em
  }
}

.section__header {
  position: relative;
  margin-bottom: 1em
}

@media (min-width: 750px) {
  .section__header {
      margin-bottom: 1.5em
  }
}

.section__title {
  color: #333333
}

.section__text {
  margin-top: 0.25em
}

.section__hanging-icon {
  margin-bottom: 1em
}

@media (min-width: 750px) and (max-width: 999px) {
  .section__hanging-icon {
      position: absolute;
      right: 100%;
      top: -0.4em;
      margin-right: 1.5em
  }
}

@media (min-width: 1300px) {
  .section__hanging-icon {
      position: absolute;
      right: 100%;
      top: -0.4em;
      margin-right: 1.5em
  }
}

.section__content {
  zoom: 1
}

.section__content:after,
.section__content:before {
  content: "";
  display: table
}

.section__content:after {
  clear: both
}

.section__content__text {
  margin-top: 0.75em
}

.section__content__policy {
  margin-top: 1em
}

.section__content__column {
  box-sizing: border-box;
  margin-top: 2em
}

.section__content__column:first-of-type {
  margin-top: 0
}

@media (min-width: 750px) {
  .section__content__column {
      margin-top: 0;
      float: left
  }
}

@media (min-width: 750px) {
  .section__content__column--half {
      padding: 0 0.75em;
      width: 50%
  }
  .section__content__column--half:first-child {
      padding-left: 0
  }
  .section__content__column--half:last-child {
      padding-right: 0
  }
}

@media (min-width: 750px) and (max-width: 999px) {
  .section--page-title {
      margin-top: 1em
  }
}

.has-modal body {
  overflow: hidden
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px)
}

.js .modal-backdrop {
  -webkit-transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, background-color 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, background-color 0.5s ease-in-out
}

.modal-backdrop.modal-backdrop--centered-content {
  display: -webkit-flex;
  display: flex
}

.modal-backdrop.modal-backdrop--is-closing.modal-backdrop--fade-out-white {
  background-color: rgba(255, 255, 255, 0.8)
}

.modal-backdrop--is-visible {
  visibility: visible;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  opacity: 1
}

.modal {
  background: #fff;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  color: #545454;
  min-height: auto;
  visibility: hidden
}

@media (min-width: 750px) {
  .modal {
      border-radius: 8px;
      margin: 4em auto 5%;
      max-width: 60em;
      min-height: 0;
      min-height: initial;
      width: 90%
  }
}

.modal.modal--centered {
  -webkit-align-self: center;
  align-self: center
}

.modal-backdrop--is-visible .modal {
  -webkit-animation: modal-open 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  animation: modal-open 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s
}

.no-cssanimations .modal-backdrop--is-visible .modal {
  visibility: visible
}

.modal-backdrop--is-closing .modal {
  -webkit-animation: modal-close 0.25s ease-in-out;
  animation: modal-close 0.25s ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards
}

.modal__header,
.modal__content {
  padding: 1.5em
}

@media (min-width: 750px) {
  .modal__header,
  .modal__content {
      padding: 2.5em
  }
}

.modal__header {
  border-bottom: 1px solid #e6e6e6;
  zoom: 1
}

.modal__header:after,
.modal__header:before {
  content: "";
  display: table
}

.modal__header:after {
  clear: both
}

.display-table .modal__header {
  display: table;
  box-sizing: border-box;
  width: 100%
}

.modal__header__title {
  color: #333333
}

.display-table .modal__header__title {
  display: table-cell;
  width: 100%
}

.no-js .modal__header__title,
.no-display-table .modal__header__title {
  float: left
}

@media (min-width: 750px) {
  .modal__content p {
      font-size: 1.14286em
  }
}

.modal__iframe {
  width: 100%
}

.modal__loading-icon {
  display: block;
  margin: 1em auto
}

.modal__footer {
  background: #f3f3f3;
  border-top: solid 1px #c6c6c6;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0.75em 1.5em
}

@media (min-width: 750px) {
  .modal__footer {
      padding: 1.25em 2.5em
  }
}

.modal__close {
  position: relative;
  -webkit-transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
  vertical-align: middle;
  padding-left: 0.75em;
  white-space: nowrap
}

.display-table .modal__close {
  display: table-cell
}

.no-js .modal__close,
.no-display-table .modal__close {
  float: right
}

.modal__close-button {
  color: #737373
}

.modal__close-button:hover {
  color: #545454
}

.fieldset {
  margin: -0.42857em;
  zoom: 1
}

.fieldset:after,
.fieldset:before {
  content: "";
  display: table
}

.fieldset:after {
  clear: both
}

.fieldset--inline {
  display: -webkit-flex;
  display: flex
}

.fieldset--inline .field {
  -webkit-flex: 1;
  flex: 1 1
}

@media (max-width: 749px) {
  .fieldset--inline {
      -webkit-flex-direction: column;
      flex-direction: column
  }
}

.fieldset--inline .btn {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  margin: 0.42857em;
  width: auto
}

@media (min-width: 750px) {
  .anyflexbox .fieldset--inline .btn {
      padding-top: 0;
      padding-bottom: 0
  }
}

.fieldset-description {
  margin-top: 0.92857em
}

.field {
  width: 100%;
  float: left;
  padding: 0.42857em;
  box-sizing: border-box
}

@media (min-width: 750px) {
  .floating-labels .field--third {
      width: 33.33333%
  }
  .floating-labels .field--two-thirds {
      width: 66.66667%
  }
  .floating-labels .field--half {
      width: 50%
  }
  .floating-labels .field--three-eights {
      width: 37.5%
  }
  .floating-labels .field--quarter {
      width: 25%
  }
}

.field__message {
  line-height: 1.3em;
  margin: 0.57143em 0 0.28571em
}

.field__message--error {
  display: none;
  color: #e32c2b
}

.field--error .field__message--error {
  display: block
}

.field__message__icon {
  margin-right: 0.25em;
  vertical-align: -3px
}

.field__label {
  text-align: left;
  font-weight: 500;
  margin: 0.5em 0;
  display: none
}

.main .field__label {
  color: #333333
}

.floating-labels .main .field__label {
  color: #737373
}

.sidebar .field__label {
  color: #303030
}

.floating-labels .sidebar .field__label {
  color: #737373
}

.content-box .field__label {
  color: #333333
}

.floating-labels .content-box .field__label {
  color: #737373
}

.default-background .field__label {
  color: #333333
}

.floating-labels .default-background .field__label {
  color: #737373
}

.main .field--active .field__label {
  color: #545454
}

.sidebar .field--active .field__label {
  color: #545454
}

.content-box .field--active .field__label {
  color: #545454
}

.default-background .field--active .field__label {
  color: #545454
}

.no-js .field__label {
  display: block
}

.floating-labels .field__label {
  font-size: 0.85714em;
  font-weight: normal;
  position: absolute;
  top: 0;
  width: 100%;
  margin-top: 0.42857em;
  margin-left: 1px;
  padding: 0 0.91667em;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  opacity: 0
}

.floating-labels .field--show-floating-label .field__label {
  -webkit-transform: none;
  transform: none;
  opacity: 1
}

.floating-labels .field__input-wrapper--icon-left .field__label {
  padding-left: 2.85714rem
}

.floating-labels .field__input-wrapper--icon-right .field__label {
  padding-right: 2.85714rem
}

.animate-floating-labels .field__label {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out
}

.field__label--visible {
  display: block
}

.field__input-wrapper {
  position: relative
}

.field__input {
  border: 1px transparent solid;
  background-clip: padding-box;
  border-radius: 5px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 0.92857em 0.78571em;
  word-break: normal
}

.main .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9
}

.main .field__input::-webkit-input-placeholder {
  color: #737373
}

.main .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1
}

.main .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1
}

.main .field__input:-ms-input-placeholder {
  color: #737373
}

.sidebar .field__input {
  background-color: white;
  color: #333333;
  border-color: #cbcbcb
}

.sidebar .field__input::-webkit-input-placeholder {
  color: #737373
}

.sidebar .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1
}

.sidebar .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1
}

.sidebar .field__input:-ms-input-placeholder {
  color: #737373
}

.content-box .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9
}

.content-box .field__input::-webkit-input-placeholder {
  color: #737373
}

.content-box .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1
}

.content-box .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1
}

.content-box .field__input:-ms-input-placeholder {
  color: #737373
}

.default-background .field__input {
  background-color: white;
  color: #333333;
  border-color: #d9d9d9
}

.default-background .field__input::-webkit-input-placeholder {
  color: #737373
}

.default-background .field__input:-moz-placeholder {
  color: #737373;
  opacity: 1
}

.default-background .field__input::-moz-placeholder {
  color: #737373;
  opacity: 1
}

.default-background .field__input:-ms-input-placeholder {
  color: #737373
}

.animate-floating-labels .field__input {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out
}

.floating-labels .field--show-floating-label .field__input {
  padding-top: 1.5em;
  padding-bottom: 0.35714em
}

.floating-labels .field--show-floating-label .field__input::-webkit-input-placeholder {
  color: transparent
}

.floating-labels .field--show-floating-label .field__input:-moz-placeholder {
  color: transparent;
  opacity: 1
}

.floating-labels .field--show-floating-label .field__input::-moz-placeholder {
  color: transparent;
  opacity: 1
}

.floating-labels .field--show-floating-label .field__input:-ms-input-placeholder {
  color: transparent
}

.field__input:focus,
.field__input-wrapper--flag-focus .field__input {
  outline: none;
  border-color: #1c1b1b;
  box-shadow: 0 0 0 1px #1c1b1b
}

@media (-ms-high-contrast: active) {
  .field__input:focus,
  .field__input-wrapper--flag-focus .field__input {
      background-color: highlight
  }
}

.field--error .field__input {
  border-color: #e32c2b;
  box-shadow: 0 0 0 1px #e32c2b
}

.field__input-wrapper--icon-left .field__input {
  padding-left: 2.85714rem
}

.field__input-wrapper--icon-right .field__input {
  padding-right: 2.85714rem
}

.field__input-wrapper--flag-right .field__input {
  padding-right: 4.71429rem
}

.chrome .card-fields-container .field__label {
  will-change: transform
}

.field__input--iframe-container {
  border-color: #fafafa;
  min-height: 3em;
  overflow: hidden;
  padding: 0;
  position: relative
}

.floating-labels .field--show-floating-label .field__input--iframe-container {
  padding-bottom: 0;
  padding-top: 0
}

.field__input--iframe-container::after,
.field__input--iframe-container::before {
  content: '';
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1
}

.field__input--iframe-container::before {
  background: #e6e6e6;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.6s ease-out;
  transition: all 0.6s ease-out;
  width: 100%
}

.field__input--iframe-container::after {
  -webkit-animation: load 1s infinite ease-in-out both;
  animation: load 1s infinite ease-in-out both;
  background: #fafafa;
  border-radius: 8px;
  height: 1em;
  margin-top: -0.5em;
  top: 50%;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out
}

.card-fields-container--loaded .field__input--iframe-container {
  height: auto
}

.card-fields-container--loaded .field__input--iframe-container::after,
.card-fields-container--loaded .field__input--iframe-container::before {
  opacity: 0
}

.card-fields-container--loaded .field__input--iframe-container::after {
  left: 100%
}

.card-fields-container--transitioned .field__input--iframe-container::after,
.card-fields-container--transitioned .field__input--iframe-container::before {
  content: none
}

.card-fields-container .field__message--error {
  color: #fafafa;
  margin: 0;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out
}

.card-fields-container--loaded .field__message--error {
  color: #e32c2b;
  margin: 0.75em 0 0.25em;
  max-height: 5em
}

.card-fields-container--loaded .field--active .field__input--iframe-container {
  outline: none;
  border-color: #1c1b1b;
  box-shadow: 0 0 0 1px #1c1b1b
}

.card-fields-container--loaded .field--error .field__input--iframe-container {
  border-color: #e32c2b;
  box-shadow: 0 0 0 1px #e32c2b
}

@-webkit-keyframes load {
  0% {
      left: 1em;
      width: 0
  }
  50% {
      left: 1em;
      width: calc(100% - 2em)
  }
  100% {
      left: calc(100% - 1em);
      width: 0
  }
}

@keyframes load {
  0% {
      left: 1em;
      width: 0
  }
  50% {
      left: 1em;
      width: calc(100% - 2em)
  }
  100% {
      left: calc(100% - 1em);
      width: 0
  }
}

.card-fields-iframe {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  color: inherit;
  display: block;
  font: inherit;
  -webkit-font-smoothing: inherit;
  height: 3em;
  line-height: normal;
  width: 100%
}

.card-fields-styling-options {
  display: none;
  position: relative;
  background-color: white;
  color: #333333;
  z-index: 22
}

.field__input--zip {
  text-transform: uppercase
}

.field__input--zip::-webkit-input-placeholder {
  text-transform: none
}

.field__input--zip:-moz-placeholder {
  text-transform: none;
  opacity: 1
}

.field__input--zip::-moz-placeholder {
  text-transform: none;
  opacity: 1
}

.field__input--zip:-ms-input-placeholder {
  text-transform: none
}

.field__input--select {
  padding-right: 2.07143em;
  white-space: nowrap
}

.field__input--select:-moz-focusring {
  color: transparent;
  -webkit-transition: color 0ms;
  transition: color 0ms
}

.main .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333
}

.sidebar .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333
}

.content-box .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333
}

.default-background .field__input--select:-moz-focusring {
  text-shadow: 0 0 0 #333333
}

.field__caret {
  display: block;
  width: 2.14286em;
  height: 43%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%)
}

.main .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid
}

.sidebar .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid
}

.content-box .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid
}

.default-background .field__caret {
  border-left: 1px rgba(179, 179, 179, 0.5) solid
}

.field__caret-svg {
  position: absolute;
  margin-left: -2px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.field__icon {
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 2.85714rem
}

.field__icon:focus {
  outline: none
}

.field__input-wrapper--icon-right .field__icon {
  right: 0
}

.field__input-wrapper--icon-left .field__icon {
  left: 0
}

.field__icon-svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.anyflexbox .field__input-btn-wrapper {
  display: -webkit-flex;
  display: flex
}

.anyflexbox .field__input-btn-wrapper .field__input-wrapper {
  -webkit-flex-grow: 1;
  flex-grow: 1
}

.anyflexbox .field__input-btn {
  width: auto;
  margin-left: 0.85714em;
  white-space: nowrap;
  padding-top: 0;
  padding-bottom: 0
}

.no-js .field__input-btn,
.no-anyflexbox .field__input-btn {
  margin-top: 0.85714em;
  padding: 1em 1.5em
}

.combo-box {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px rgba(2, 2, 2, 0.15) solid;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.23);
  margin-top: 0.42857em;
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  z-index: 3
}

.combo-box--hidden {
  display: none
}

.combo-box--with-footer .combo-box__options {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0
}

.combo-box--with-footer .combo-box__option:last-child {
  border-radius: 0
}

.combo-box__options {
  display: block;
  overflow-y: auto;
  max-height: 230px
}

.combo-box__option {
  color: #545454;
  cursor: pointer
}

.combo-box__option:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px
}

.combo-box__option--selected {
  background-color: rgba(0, 0, 0, 0.08);
  border: 1px transparent solid
}

.combo-box__option--selected .combo-box__content {
  margin: -1px
}

.combo-box__content {
  box-sizing: border-box;
  text-align: left;
  width: 100%;
  padding: 0.92857em 0.78571em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.combo-box__content * {
  pointer-events: none
}

.combo-box__header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0.78571em
}

.combo-box__header__title {
  font-size: 0.85714em;
  font-weight: 400;
  line-height: 1.3em;
  margin: 0;
  text-transform: uppercase
}

.main .combo-box__header__title {
  color: #737373
}

.sidebar .combo-box__header__title {
  color: #737373
}

.content-box .combo-box__header__title {
  color: #737373
}

.default-background .combo-box__header__title {
  color: #737373
}

.combo-box__header__button {
  color: #737373;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  padding: 0.78571em;
  margin: -0.78571em
}

.combo-box__header__button:hover {
  color: #545454
}

.combo-box__footer {
  background-color: rgba(0, 0, 0, 0.02);
  padding: 0.74286em 0.78571em;
  position: relative;
  z-index: 4;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px
}

.content-box {
  background: #fff;
  background-clip: padding-box;
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
  color: #545454
}

.content-box h2:only-child {
  margin: 0
}

.content-box,
.content-box-spacing {
  margin-top: 1em
}

.content-box:first-of-type,
.content-box-spacing:first-of-type {
  margin-top: 0
}

.content-box__emphasis {
  font-weight: 500;
  color: #333333
}

.content-box__small-text {
  font-size: 0.85714em;
  color: #737373
}

.content-box__row {
  padding: 1.14286em;
  position: relative;
  zoom: 1
}

.content-box__row ~ .content-box__row {
  border-top: 1px solid #d9d9d9
}

.content-box__row:after,
.content-box__row:before {
  content: "";
  display: table
}

.content-box__row:after {
  clear: both
}

.display-table .content-box__row {
  display: table;
  box-sizing: border-box;
  width: 100%
}

.content-box__row:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

.content-box__row:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px
}

.content-box__row--tight-spacing-vertical {
  padding-top: 0.85714em;
  padding-bottom: 0.85714em
}

.content-box__row--secondary {
  background-color: #fafafa
}

.content-box__row--no-border {
  padding-bottom: 0
}

.content-box__row+.content-box__row--no-border {
  border-top: none;
  padding-top: 0
}

.content-box__row--no-border+.content-box__row {
  border-top: none
}

.content-box__row--no-padding {
  padding: 0
}

.content-box__row--with-footer {
  padding-bottom: 0.85714em
}

.display-table .content-box__row__stretch {
  display: table-cell;
  width: 100%
}

.no-js .content-box__row__stretch,
.no-display-table .content-box__row__stretch {
  float: left
}

.content-box__row__right {
  padding-left: 0.75em;
  white-space: nowrap
}

.display-table .content-box__row__right {
  display: table-cell
}

.no-js .content-box__row__right,
.no-display-table .content-box__row__right {
  float: right
}

.content-box__hr {
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  margin: 1.14286em 0
}

.content-box__hr--tight {
  margin: 0.85714em 0
}

.content-box--applied-reduction-code-list {
  margin-top: 0.85714em;
  background-color: #fafafa
}

.content-box__header {
  zoom: 1
}

.content-box__header:after,
.content-box__header:before {
  content: "";
  display: table
}

.content-box__header:after {
  clear: both
}

.display-table .content-box__header {
  display: table;
  box-sizing: border-box;
  width: 100%
}

.content-box__header ~ .content-box__header {
  margin-top: 1.14286em
}

.display-table .content-box__header__title {
  display: table-cell;
  width: 100%
}

.no-js .content-box__header__title,
.no-display-table .content-box__header__title {
  float: left
}

.content-box__footer {
  border-top: 1px solid #e6e6e6;
  padding-top: 0.85714em;
  margin-top: 0.85714em
}

@media (max-width: 999px) {
  .order-summary--transition,
  .order-summary--is-collapsed {
      overflow: hidden
  }
}

@media (max-width: 999px) {
  .js .order-summary--is-collapsed {
      height: 0;
      visibility: hidden
  }
}

.order-summary--transition {
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1)
}

.order-summary__section {
  padding-top: 1.5em;
  padding-bottom: 1.5em
}

.order-summary__section ~ .order-summary__section {
  border-top: 1px solid rgba(167, 167, 167, 0.34)
}

@media (min-width: 1000px) {
  .order-summary__section {
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto
  }
  .order-summary__section:first-child {
      padding-top: 0
  }
}

@media (min-width: 1000px) {
  .order-summary__sections {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      height: calc(100vh - 8em);
      width: 100%
  }
  .no-js .order-summary__sections,
  .no-anyflexbox .order-summary__sections {
      height: auto
  }
  .order-summary__section--product-list {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 0 1 auto;
      flex: 0 1 auto;
      min-height: 7em;
      overflow: hidden;
      padding: 0;
      position: relative
  }
  .order-summary__section--product-list+.order-summary__section {
      border: 0
  }
  .order-summary__section--product-list::before,
  .order-summary__section--product-list::after {
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      -webkit-transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
      transition: background-color 0.3s cubic-bezier(0.3, 0, 0, 1);
      width: 100%;
      z-index: 4
  }
  .order-summary__section--product-list::before {
      background-color: transparent;
      top: 0
  }
  .order-summary__section--product-list::after {
      background-color: rgba(167, 167, 167, 0.34);
      bottom: 0
  }
  .order-summary__section--product-list .product-table {
      margin-top: 0.75em;
      margin-bottom: 1.5em;
      position: relative;
      z-index: 1
  }
  .order-summary__section--has-scroll::before {
      background-color: rgba(167, 167, 167, 0.34)
  }
  .order-summary__section__content {
      min-width: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch
  }
  .no-js .order-summary__section__content,
  .no-touchevents .order-summary__section__content {
      overflow: hidden
  }
  .no-js .order-summary__section__content:hover,
  .no-touchevents .order-summary__section__content:hover {
      overflow-y: auto
  }
}

.order-summary__scroll-indicator {
  background-color: rgba(48, 48, 48, 0.85);
  border-radius: 2em;
  bottom: 1.5em;
  color: #efefef;
  font-size: 0.85714em;
  left: 50%;
  opacity: 0;
  padding: 0.6em 1.2em;
  position: absolute;
  -webkit-transform: translateX(-50%) translateY(100%) rotateZ(360deg);
  transform: translateX(-50%) translateY(100%) rotateZ(360deg);
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  white-space: nowrap;
  z-index: 2
}

.order-summary__section--is-scrollable .order-summary__scroll-indicator {
  opacity: 1;
  -webkit-transform: translateX(-50%) translateY(0) rotateZ(360deg);
  transform: translateX(-50%) translateY(0) rotateZ(360deg)
}

.order-summary__section--has-scroll .order-summary__scroll-indicator {
  -webkit-transform: translateX(-50%) translateY(-100%) rotateZ(360deg);
  transform: translateX(-50%) translateY(-100%) rotateZ(360deg)
}

.order-summary__scroll-indicator .icon-svg {
  margin-left: 0.21429em;
  margin-top: -0.21429em
}

.order-summary__emphasis {
  font-weight: 500
}

.sidebar .order-summary__emphasis {
  color: #303030
}

.content-box .order-summary__emphasis {
  color: #333333
}

.order-summary__small-text {
  font-size: 0.85714em
}

.sidebar .order-summary__small-text {
  color: #6c6c6c
}

.content-box .order-summary__small-text {
  color: #737373
}

.total-line th,
.total-line td {
  padding-top: 0.75em
}

.total-line:first-child th,
.total-line:first-child td {
  padding-top: 0
}

.content-box .total-line__name {
  color: #737373
}

.total-line__name .reduction-code {
  margin-left: 0.57143em
}

.total-line__price {
  padding-left: 1.5em;
  text-align: right;
  white-space: nowrap
}

.total-line--shipping-policy-link {
  padding-top: 0.25em
}

.total-line-table__tbody+.total-line-table__footer .total-line th,
.total-line-table__tbody+.total-line-table__footer .total-line td {
  padding-top: 3em;
  position: relative
}

.total-line-table__tbody+.total-line-table__footer .total-line th::before,
.total-line-table__tbody+.total-line-table__footer .total-line td::before {
  content: '';
  position: absolute;
  top: 1.5em;
  left: 0;
  width: 100%;
  height: 1px
}

.sidebar .total-line-table__tbody+.total-line-table__footer .total-line th::before,
.sidebar .total-line-table__tbody+.total-line-table__footer .total-line td::before {
  background-color: rgba(167, 167, 167, 0.34)
}

.content-box .total-line-table__tbody+.total-line-table__footer .total-line th::before,
.content-box .total-line-table__tbody+.total-line-table__footer .total-line td::before {
  background-color: rgba(179, 179, 179, 0.34)
}

.payment-due-label__total {
  font-size: 1.14286em
}

.sidebar .payment-due-label__total {
  color: #303030
}

.content-box .payment-due-label__total {
  color: #333333
}

.payment-due-label__taxes {
  display: block
}

.sidebar .payment-due-label__taxes {
  color: #6c6c6c
}

.content-box .payment-due-label__taxes {
  color: #737373
}

.payment-due__price {
  font-size: 1.71429em;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1em
}

.sidebar .payment-due__price {
  color: #303030
}

.content-box .payment-due__price {
  color: #333333
}

.payment-due__currency {
  font-size: 0.85714em;
  vertical-align: 0.2em;
  margin-right: 0.5em
}

.sidebar .payment-due__currency {
  color: #6c6c6c
}

.content-box .payment-due__currency {
  color: #737373
}

.reduction-code__icon {
  margin-right: 0.14286em;
  vertical-align: top
}

.reduction-code__text {
  font-size: 1em
}

.sidebar .reduction-code__text {
  color: #303030
}

.content-box .reduction-code__text {
  color: #333333
}

.full-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  background: white;
  color: #545454
}

.full-page-overlay__wrap {
  margin: auto;
  width: 100%;
  padding: 10% 0
}

.full-page-overlay__title {
  color: #333333;
  margin-bottom: 0.5em
}

.full-page-overlay__content {
  margin: 0 auto;
  max-width: 36em;
  padding-left: 1em;
  padding-right: 1em;
  zoom: 1
}

.full-page-overlay__content:after,
.full-page-overlay__content:before {
  content: "";
  display: table
}

.full-page-overlay__content:after {
  clear: both
}

.full-page-overlay__content form {
  margin: 1.5em 0
}

.full-page-overlay__content:focus {
  outline: 0
}

.full-page-overlay__icon {
  margin-bottom: 1.5em
}

.full-page-overlay__illustration {
  margin-bottom: 1.5em
}

.tooltip {
  box-sizing: border-box;
  position: absolute;
  bottom: 80%;
  right: -0.33333em;
  width: 180px;
  margin: 0 0 5px 0;
  padding: 0.75em 1em;
  border-radius: 4px;
  white-space: normal;
  font-size: 0.85714em;
  font-style: normal;
  text-align: center;
  color: #fff;
  display: block;
  z-index: 200;
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  -webkit-transform: rotateX(20deg) scale(0.8);
  transform: rotateX(20deg) scale(0.8);
  -webkit-transform-origin: 90% 120%;
  transform-origin: 90% 120%;
  -webkit-backface-visibility: hidden;
  background-color: rgba(51, 51, 51, 0.9);
  visibility: hidden;
  opacity: 0
}

.tooltip:after {
  content: "";
  position: absolute;
  top: 100%;
  right: 18px;
  width: 0;
  border: 6px solid transparent;
  border-top-color: rgba(51, 51, 51, 0.9)
}

@media (min-width: 750px) {
  .tooltip {
      -webkit-transform-origin: center 120%;
      transform-origin: center 120%;
      right: 50%;
      margin-right: -90px
  }
  .tooltip:after {
      right: 50%;
      margin-right: -6px
  }
}

.has-tooltip {
  cursor: pointer
}

.has-tooltip:hover .tooltip,
.has-tooltip:focus .tooltip {
  -webkit-transform: rotateX(0deg) scale(1);
  transform: rotateX(0deg) scale(1);
  pointer-events: auto;
  visibility: visible;
  opacity: 1
}

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap
}

@media (max-width: 999px) {
  .breadcrumb {
      display: none
  }
}

.breadcrumb__item {
  display: inline;
  font-size: 0.85714em;
  color: #737373
}

.anyflexbox .breadcrumb__item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center
}

.breadcrumb__item--current {
  font-weight: 500;
  color: #333333
}

.breadcrumb__chevron-icon {
  margin: 0 0.64286em
}

.breadcrumb--center {
  text-align: center;
  -webkit-justify-content: center;
  justify-content: center
}

.main__footer {
  padding: 1em 0;
  border-top: 1px solid #e6e6e6
}

.copyright-text {
  font-size: 0.85714em;
  color: #737373
}

.policy-list {
  zoom: 1
}

.policy-list:after,
.policy-list:before {
  content: "";
  display: table
}

.policy-list:after {
  clear: both
}

.policy-list__item {
  float: left;
  font-size: 0.85714em;
  margin-right: 1.5em;
  margin-bottom: 0.5em
}

.radio-wrapper,
.checkbox-wrapper {
  zoom: 1;
  margin-bottom: 1em
}

.radio-wrapper:after,
.radio-wrapper:before,
.checkbox-wrapper:after,
.checkbox-wrapper:before {
  content: "";
  display: table
}

.radio-wrapper:after,
.checkbox-wrapper:after {
  clear: both
}

.display-table .radio-wrapper,
.display-table .checkbox-wrapper {
  display: table;
  box-sizing: border-box;
  width: 100%
}

.radio-wrapper:last-child,
.checkbox-wrapper:last-child {
  margin-bottom: 0
}

.radio-wrapper.content-box__row,
.checkbox-wrapper.content-box__row {
  margin-bottom: 0
}

.radio__input,
.checkbox__input {
  padding-right: 0.75em;
  white-space: nowrap
}

.display-table .radio__input,
.display-table .checkbox__input {
  display: table-cell
}

.no-js .radio__input,
.no-display-table .radio__input,
.no-js .checkbox__input,
.no-display-table .checkbox__input {
  float: left
}

.radio__label,
.checkbox__label {
  cursor: pointer;
  vertical-align: middle
}

.display-table .radio__label,
.display-table .checkbox__label {
  display: table-cell;
  width: 100%
}

.no-js .radio__label,
.no-display-table .radio__label,
.no-js .checkbox__label,
.no-display-table .checkbox__label {
  float: left
}

.radio__label--inactive {
  cursor: default
}

.radio__label__primary {
  cursor: inherit;
  font-family: inherit;
  vertical-align: top
}

.display-table .radio__label__primary {
  display: table-cell;
  width: 100%
}

.no-js .radio__label__primary,
.no-display-table .radio__label__primary {
  float: left
}

.radio__label__accessory {
  text-align: right;
  padding-left: 0.75em;
  white-space: nowrap
}

.display-table .radio__label__accessory {
  display: table-cell
}

.no-js .radio__label__accessory,
.no-display-table .radio__label__accessory {
  float: right
}

.payment-method-wrapper .radio__label__accessory {
  vertical-align: middle
}

@media (max-width: 749px) {
  .payment-method-wrapper .radio-wrapper {
      display: block
  }
  .payment-method-wrapper .radio__label,
  .payment-method-wrapper .radio__label__accessory {
      display: block;
      padding-left: 0;
      text-align: left
  }
  .payment-method-wrapper .radio__label__primary::after {
      content: '';
      display: block;
      height: 0.65em
  }
}

.payment-icon {
  display: inline-block;
  width: 38px;
  height: 24px;
  -webkit-transition: opacity 0.5s cubic-bezier(0.3, 0, 0, 1);
  transition: opacity 0.5s cubic-bezier(0.3, 0, 0, 1);
  -webkit-backface-visibility: hidden
}

.blank-slate .payment-icon {
  vertical-align: middle
}

.payment-icon--elo {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/elo-aed004d5345e0c5cb0d402620f1dbac8f390e6f42fde7e8104d36e2542e20bb8.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/elo-2d06f4b3098dea7e1006157d01eaad1577bb31079e593b759b3892d7d2b9453b.svg), none
}

.payment-icon--dailyyamazaki {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dailyyamazaki-fe197b1ca54228cd66956f1a092bdd41594abb3e6c6147fe78a6390e7834dfd4.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dailyyamazaki-68b5213c2536d408c669d39ccfb9d068a895d5f7ab121e3bc3e3fe91b74952f4.svg), none
}

.payment-icon--shopify-pay {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/shopify_pay-ff2c4460a17e35340187e6ca2521593eac9f0f789c38d8ec72add951c7215a1b.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/shopify_pay-d062d90212816e724f16662d29ca6e52a065d3a4cf538472b0b0c51c6f7c2ce6.svg), none
}

.payment-icon--rhbbank {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/rhbbank-8c37a4d5adfb796110bb706f1c6cbba0e6105a8320fb3356073000645b5aeff7.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/rhbbank-9245be5f04d60a3c78db12299175be69a875e1c1e8321c3a31abdd4bd5c7aa34.svg), none
}

.payment-icon--enets {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/enets-df7528633678642bc6d62ca5bc390a8d92e9d85c0760c4adcf5cd15dfab1faaa.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/enets-7ea95d3aac6ed0dca6b6b801f6ed6ec52e753144a45234bf3de43c18e0b90259.svg), none
}

.payment-icon--dwolla {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dwolla-82e6debd274338f26992890e20f6e6e39450c82bc5e60c46138dba3ef1d35706.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dwolla-afe7aa3f31ed9f5aaf470e495448ee3f17a139aa8692a50d117571174726ce8d.svg), none
}

.payment-icon--publicbank {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/publicbank-f98b78e4fd2107516178ac26d36f17ee4af5426d6f008ae05940223c2fe264d9.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/publicbank-4a3591d355f305e5988dfa25e39ad4eb4fa093fa8fa807317cc203aa1310dd08.svg), none
}

.payment-icon--twint {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/twint-708750dda7cb1e0b385e9ab55cf1e1e82dd00c9d691975e51c8201afd7463d0f.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/twint-805455506f9dfb7b0b7029a0a20e4e06503402099a7368134c8d5c786506cae3.svg), none
}

.payment-icon--apple-pay {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/apple_pay-74e73aec2cd06a8f8898fb750056a5f4bbd6b066be02187e6a903fc87a9170fb.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/apple_pay-f6db0077dc7c325b436ecbdcf254239100b35b70b1663bc7523d7c424901fa09.svg), none
}

.payment-icon--paysera {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/paysera-b4808b17347eb111b175757686e4b30e2e007f110238563d9552504d69dbd7d9.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/paysera-0a2dd811666f5f5db1bdfec1bfbe2e092fcff9a58337068ffaab53444862088b.svg), none
}

.payment-icon--viabill {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/viabill-8a10feb8cd7ea33a8c1ab834126a01ed4e903d39f8f3e098d7b03539587821d6.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/viabill-d7c752b7e5cabd7898f3762e601f2c038f3596ccd5e97d36d042984245ae3002.svg), none
}

.payment-icon--familymart {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/familymart-c26f2e5c7fe096a21923031c734255a36bb112b1b9dc8494e111d7f471558afe.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/familymart-8b7f2baff828541efeeeb9b934894bdb6a53561f1a5e2d0b24b2b9fec72b5cdb.svg), none
}

.payment-icon--sezzle {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/sezzle-30e3b3035ad3c718edc7e9c18dcea6367dede2fa720773dbe95e5ac394cb3558.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/sezzle-be9c40cd44c1b6a8c44430f485076b0bc8b59d9cfaa1db32c4129fb8e63222e5.svg), none
}

.payment-icon--klarna-slice-it {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/klarna-slice-it-0713f3cb2e0f26aa7c04a784b8e95c1f97c781976c65da02798f86817a195415.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/klarna-slice-it-4c6df0890639f3019d29062980feebff6c73d8996920bae74c02a7973607ea7f.svg), none
}

.payment-icon--masterpass {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/masterpass-3cd641c40c81baa21b92c2ef259bf6772c1e200ab82ddae2d97dbfc51125c8d0.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/masterpass-093da920f571561eb31d76cb8c178b50f9562b6f02e628b4c9b6d45848caf5b8.svg), none
}

.payment-icon--american-express {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/american_express-912502ce04f648c0f82d5bc32df91619760eecc72ca036df9f2b83ddc7af3d59.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/american_express-ed5c54cf3ceb18cd4deb3687857b816c07e4f4c7e8719da4a206cea3e7961be1.svg), none
}

.payment-icon--hyper {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/hyper-a2397c2b726801585aa01c0f3f63d9a319ae67fbbc4b4cdaa85e6f780dc18e25.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/hyper-3528663954f9e058c5ca12cdea12f849185a6d9d132973c69f2102fa3f3fef33.svg), none
}

.payment-icon--ministop {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/ministop-8daee149fbe41bf5540a7a2316cd1e882392f5096ae37e13dac100a29454ea08.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/ministop-6d5c82e75851ed7c87c7312616759b9592b173cf7758f25d4aea8eb378510c2f.svg), none
}

.payment-icon--santander {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/santander-525053fda27294a96c3db0252cd7ffc3a4d1acd8c10b88f2d45beefc755d0e0f.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/santander-589d566d640572dfc3a5a2dfb15d145494157f417362e730868ec571e1a49319.svg), none
}

.payment-icon--ola-money {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/ola_money-a095ab028bfb7c4e2c19f2b734f560e5e363f6396dd6a1a629a4a460ee90133c.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/ola_money-56c15887176a93ecce0a1737404effa4e659770e447fc270103a511d2f8458df.svg), none
}

.payment-icon--paysafecard {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/paysafecard-6f65127c5c7790ddfa353a889beb41862b96af3f3e52a71f3b72ec4bdf961db7.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/paysafecard-1bba93a00141d0964cef60e4fac4281320dd7af0ab5eff88068a05492aa6dd64.svg), none
}

.payment-icon--seb {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/seb-7b49800f0d21e2d6de39620c3174092672eab15165dc950aca5ae7b8d72a06e2.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/seb-3ac27e0653ef348ee9ad2be6dc7abbc2e0ceb16fdb91203221cf0ad18e4ff9f7.svg), none
}

.payment-icon--op {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/op-e6ed9e952b19790ab5d1d2686b0ad77850956423abf50ee28e47df3a5fa3850d.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/op-2396f2c15692f6439ec9e2b556322a7707b90d64c7a2bda30478e6ab0163a94a.svg), none
}

.payment-icon--jousto {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/jousto-d96b504d0cd4370d6b1eb189e9edf59468002270f3de6f68057cd9809cee126b.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/jousto-494917e43495f11028696231f6159579677d776b0dcc986755af1fd1e78870f2.svg), none
}

.payment-icon--mondido {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/mondido-f23affdadadb4a5fcc3c910fea1a378ca92eb707d4250710d696d6535b51ee17.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/mondido-b8be410a601537bfb7608a7d3fe9b7dc18be5cb7574260b89d98a2dc4f69bb80.svg), none
}

.payment-icon--venmo {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/venmo-f0384c2d6c25f17075e9c606e87e7e6cf21bcc143372a0ea6554d3503b1805d4.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/venmo-e46496b4036966108664ea6a65bfeddd373275087df5f94cc3e5564fdb493eb0.svg), none
}

.payment-icon--alipay {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/alipay-2078e24281ab29ae3ef943b69a2684e7b55f4695aefaa934c98e15fc8c4cfc02.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/alipay-e2b5ac81b49d42a7bfc880600f5dd1e8d9afccd16740cdf728b45dca80265d40.svg), none
}

.payment-icon--forbrugsforeningen {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/forbrugsforeningen-d36394d56836bf12230a6ad17084a2dcfd9b208cc261a27aee8d5db0bcfaec22.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/forbrugsforeningen-99ffce51f2e166271aa285e1497d7feecec72a562c2b97298e6bc3504931f99d.svg), none
}

.payment-icon--trustly {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/trustly-e33e5275ffcc6b0f455e3c683a2b0f9b96587548bfe2bc448face0fdacb71311.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/trustly-a18522f1fa994b9b20b446060f631e2d75d008e873b3230904473fcad27a8dd3.svg), none
}

.payment-icon--przelew24 {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/przelew24-a0761be9f3d4663c2ac2bdaa859b2998d89161b020b15dce97690776a3ae0931.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/przelew24-eb718f0ae4c1ea7af91c7199814e13c25e512629a332448930a6612d99b40bc2.svg), none
}

.payment-icon--generic {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/generic-073e5465ebcadd141f1eedbd083c91a38190ea5793cb54232e2892d3e1a06662.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/generic-dfdcaf09b6731ca14dd7441354c0ad8bc934184eb15ae1fda6a6b9e307675485.svg), none
}

.payment-icon--cimb {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/cimb-524dcefea9c18c48524ec30d7eef893f5a1f19a44e73ca393b76b8d38771b78d.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/cimb-c8c7c9d3f502553190be05539974f4dfd24307a5b1f6987f709e5c1299c18529.svg), none
}

.payment-icon--unipay {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/unipay-fd05e0513825bf4e85d095e04fc0b183259adba2de8d87e1254c93e770953164.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/unipay-60249e9da3927c24d1a6074598153940dd1f71eed38f2be31410c7edd1e66392.svg), none
}

.payment-icon--netbanking {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/netbanking-87344dda35f09404438f84ac314e3401fe3bd4d77d459b8d9c483a456e2fa10a.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/netbanking-7fea52e535bcab5f2b1b2c9705c838756cf1c218a56cc4bcfd2ce0bf6383e295.svg), none
}

.payment-icon--krediidipank {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/krediidipank-45121682e6bf818034046ce80642b5679f1cfb0ca1297f796261a6500a6c2875.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/krediidipank-51af4f784aecd8d3595e03999689f2c151d3f99d8ededb3c6fdc9dedc76d58a0.svg), none
}

.payment-icon--payfast-instant-eft {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/payfast_instant_eft-4f2cb3e7ee58fd192088828201f159ab821ebf9fa531bb556bab41e43eeeab6e.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/payfast_instant_eft-51e3c7689a32673d7bb7f1ab97d7f9650ca9a50fc625c337a6d4e8d16dc25843.svg), none
}

.payment-icon--ideal {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/ideal-bcc13597f3382cf5296f42c9340ba35095d10e594ca801305b1e1d7c022dc775.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/ideal-35160b934b25f7635f1bf94b7fbec57a1e3e44d946e811e6aba472e11142cbcd.svg), none
}

.payment-icon--pivo {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/pivo-769336d2c652a3418be77d32a4d403a91387be80c754ac3830ddc4e4f76db48b.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/pivo-f02d762d0d71a1b957c6b203a82200c807642426061d331198073f760c62585a.svg), none
}

.payment-icon--sunkus {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/sunkus-91e7541f35831ef132025ad088e873fd53dfeae1beff8e7f7873fb7d016fbca2.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/sunkus-6476dd340cb3b742ea86c590efa7afb5cc3adb7ca97dfb334653423d35e8aee5.svg), none
}

.payment-icon--discover {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/discover-daf13085dd9a9cc06a0f8d4cc2fd58ebf40492dbc28bb74b162d6f798ab2ffda.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/discover-8265cfcac046637b87df7718c1436f6a1e054de3fbbb73c2ae82db1332879ba5.svg), none
}

.payment-icon--sofort {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/sofort-1986ce02629584884e5335a283c5c417f28e57322c9b973e3208e1847133259a.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/sofort-f51a776de54e6dcc46e0d6a565ff6e567030939549da222eda719c32ea581c25.svg), none
}

.payment-icon--gift-card {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/gift-card-302eaec0956647e92efe523b4751727b2a5348dd765dac840aa53d58448b4a2e.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/gift-card-3377e9caba6a79d3ea39715f77239a24c24307242d8dfa3d35752df5410cef7e.svg), none
}

.payment-icon--amazon {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/amazon-14030bee2feea45455ca61646b83989d3e430e5e0d8e0edd80fca303ef785620.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/amazon-92e856f82cae5a564cd0f70457f11af4d58fa037cf6e5ab7adf76f6fd3b9cafe.svg), none
}

.payment-icon--swedbank {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/swedbank-ecad12a60d7c711ec119af5fea40b87093551fde16e4dd357801970afbad48e2.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/swedbank-810f6e65bc012b8060fabef3784b98dedf5195fa57ff4e8172735a2dd447eb85.svg), none
}

.payment-icon--danske-bank {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/danske_bank-3444e8ffe887da1fc1b080fb9daf0771cbdf8fbd3e5e93f109f7cb6ed36fa92f.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/danske_bank-5d0229a9de7b776cef96ac84a78738697313e3056de46ad9f387a615492a2d38.svg), none
}

.payment-icon--cash {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/cash-a1f99005b24cb42bae9f6fcfa189e65f64f3e3827436011fee69b4177602a9f4.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/cash-aca0520e6afe3967a5ec0566e9cab5dc3e39678976be8e10150a152f4d889f03.svg), none
}

.payment-icon--dnb {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dnb-f72bfe5d9c80896b452dbb1ee71c330c17c54d14640d4dc2857829d013694627.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dnb-6447b501736e8323a9b3dec1ea6318d8c776dcf57e37f8ca003f55191ce2e1d4.svg), none
}

.payment-icon--klarna-pay-now {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/klarna-pay-now-e19539d8daf1dd437973c4139fdc623e91f7edd86c8dfe0ffe0f483ac625646b.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/klarna-pay-now-813d6bf4798b6bad4ad28571b101244a7ecc999449292c8294c241dc3d42b9e8.svg), none
}

.payment-icon--nordea {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/nordea-f2c040f8b99f3bb0f45f74ce309fcf229b988be3daedd5ffc2ddb82990e51512.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/nordea-21b9557ba97b2c198f43eca2ed7b8a3ea2d8de2588b66b285d03a5896db170c5.svg), none
}

.payment-icon--esr-paymentslip-switzerland {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/esr_paymentslip_switzerland-6da4a151fae8fa71a2e032a73110a829036b46c2e4f5d898e064f78630a0df78.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/esr_paymentslip_switzerland-0e00cd25a6b2c0b0d39c22bc75c1d68065b22e5d8ea2d45afbd71db367d6e9e8.svg), none
}

.payment-icon--eos {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/eos-a217037ca4936c5afb338d20b56eb707415fe5e5f9aa143f6aeda2a90b05ef71.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/eos-93d1fb5eacb3370ff7121ee018cf7f6ff40766bb8bdb10e72d966975b1764c3b.svg), none
}

.payment-icon--litecoin {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/litecoin-0101b700894875d8d43879b05516cdf252b5cfd9a58b8fb7fc1cb1a0f0108f2b.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/litecoin-06f10bf73578fe346f5b2817673102b77c19ea71ebe05b2839495975651657c0.svg), none
}

.payment-icon--prepaysolutions {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/prepaysolutions-c94d1a1d4c78192a6dd721ed98933dedb3b7c4b45a94587b27039f7b345f9676.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/prepaysolutions-6935a76ccaa76e48b82c1b0fbfe05f27cc7d92a291845bde7989e3be98ef8d98.svg), none
}

.payment-icon--epayments {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/epayments-57f7bcf4feffa69cf5bcbbb2ea473ff932487ef8286c69fc9de52b28e8a0c1b3.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/epayments-24d4b721ecfcdf7cee62181de2803630605ea9e8a214b7e410964bcc080d9129.svg), none
}

.payment-icon--monero {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/monero-d91309cc012398d8045cd3c3b1f121444d7daf8777f65909b075d3144753c189.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/monero-3e92299bb919a4479f86d9101549077493b22713ce9cb0077d7aa9c92b6d3d0d.svg), none
}

.payment-icon--visa {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/visa-e5bb9ed6e55d7351774b2989db708125c012b93faaefd3b620aae1493ca5c76c.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/visa-319d545c6fd255c9aad5eeaad21fd6f7f7b4fdbdb1a35ce83b89cca12a187f00.svg), none
}

.payment-icon--vipps {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/vipps-11c5eb3a1db08e73e38b2cfaa870e84e961d080e9d328b7aeec0a4aa9dd90ce9.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/vipps-b944e4a9d8726be0b834fb9eaa4ca130b5ebc9d64b062580e374d9cf25304879.svg), none
}

.payment-icon--fpx {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/fpx-600f1917c279e763ce9e8b2671c75ac747c3fe1348055445f1af6a9377668851.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/fpx-ec7b2f24c5d2c9b50fa911379b2ea048cb29342ab1303e88c8984b0544603455.svg), none
}

.payment-icon--lawson {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/lawson-ed9640591b5627db8a3eada05c54ccac987dcb1f16b6a1b039ad0ca9d2792926.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/lawson-854e3a0217fd26bc17d75fed4ae85cc6850c1ae274634482e9c073ec76a1fac2.svg), none
}

.payment-icon--unionpay {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/unionpay-310bc04a83f417c3bb4277515508f92922934d70d63eb5caf2ee631f36032115.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/unionpay-8aedb760a6bde56bc2e3bbfd40bc008f1b0967a9f5863ecfbe855d54bde81ea6.svg), none
}

.payment-icon--visaelectron {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/visaelectron-10a9f65cd1e70de7fecc3f529d5a22a5e99135d0f7e06b086f61e85f3a0f1fb4.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/visaelectron-131aa567644ec0382c5762f24c751903c87a9ce4d2ffcfdb4be83be8e21d86b5.svg), none
}

.payment-icon--laybuy {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/laybuy-1b7b90804a60e11619f40204db2277b178d457ffe996c3be658c575308b2c038.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/laybuy-5c12ab49de1477417ebb0217414a0fd555695e1330be2ea7e3c6a691d980646b.svg), none
}

.payment-icon--ambank {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/ambank-7427567e10fbd4f87c90937e4f883169b122ac2caec24dfb60b1e96a95587a54.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/ambank-89ecb816f616894547157ab3af4ee1cad2242784a27bc1ce4a4ca909b017845b.svg), none
}

.payment-icon--hongleongbank {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/hongleongbank-b97cf538f1f5d4615372a5d571c1a4f77450c33be0831169c4b1f3600d42eff4.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/hongleongbank-6ac878054de5397c8e6d42f507c2832cf77de8a60e833fa2907cf773bf9434d4.svg), none
}

.payment-icon--paymark-online-eftpos {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/paymark_online_eftpos-fe12a5a79e6c31918ba6b5ca388add9f2e65ff192049362e9fb6c9b018a61f0c.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/paymark_online_eftpos-c24e05f814d7c3bbe1d75f9333f85557e7155ca6b921d024c173a356c7d60f8b.svg), none
}

.payment-icon--bitcoin-cash {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/bitcoin_cash-1d6fddec949068063979b993bd356ba4c4b1393ac550e179079c24df521c8d0f.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/bitcoin_cash-5bcc0af0a5ebe647f8da2aae1f8382d7854796dc3bc2450985a9da832d4d2bbc.svg), none
}

.payment-icon--swish {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/swish-53147a89c63a29c63fe981f572253ee68228643ed633051dd7ecadf2dbb3ed41.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/swish-9a7992c489f97955ad91aad34a6e8df0a32624384666dcb938bfa6887a971d6f.svg), none
}

.payment-icon--lhv {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/lhv-c1a0b725fab7ba771a93aee9f7b1be483c03bf9b6ac93c9fda6863f11cbf4927.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/lhv-e179fe49401f7fa1c480fb60d48a50ec0865bee5bf48527f80a0068cbadf43ee.svg), none
}

.payment-icon--rupay {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/rupay-7824ecf059b5d9fa3d616ac39033c09933694cf781fe82f35ee17dec7032d504.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/rupay-7dd8b2a3c63957a0174f9ad530376abbac88ed8baeb14d78e7887eec4208e63d.svg), none
}

.payment-icon--bitcoin {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/bitcoin-ed7d2d3a708876f43dcd357f6f04a86c06031e28de229e87040b2246131b56c8.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/bitcoin-e41278677541fc32b8d2e7fa41e61aaab2935151a6048a1d8d341162f5b93a0a.svg), none
}

.payment-icon--mobicred {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/mobicred-5df14cc77ef3238850fdd5df912af26213e2d3b04674086bf6a53b22a071a92c.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/mobicred-009c9ba82342ee660ba640b27c6f3fd9b68c68685f16a51147a3459dc7c6fbd2.svg), none
}

.payment-icon--airtel-money {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/airtel_money-dd37c3a0dee8303977e74c21c603be3b6ecf909553349f0dc309bd68aa681f4d.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/airtel_money-ba47466e863909cc190c6f7143887fc86bcbad8246a1fd788a5a1e0f5a42cc87.svg), none
}

.payment-icon--jcb {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/jcb-660256455e0b0d19e56a0f3d53a6046dd5b0bb322165899121bb20fcc6300bb3.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/jcb-ab0f5a1739704f1ab039f19ac8c28895af5c39a3f54ee9b748ea051986b0bd36.svg), none
}

.payment-icon--payd {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/payd-8f839bbcafdd7f1ce914987e824261c6e187d615d130d51663f042c78ff229b5.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/payd-2c6c5c3a0d2f26535cc31e22d77ffe58afe4f0308779c80009f80420709d1e9b.svg), none
}

.payment-icon--master {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/master-b3e9556358eab118e528bd537b2a5b8291bd33fa66a42fe85787e80352e4e383.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/master-173035bc8124581983d4efa50cf8626e8553c2b311353fbf67485f9c1a2b88d1.svg), none
}

.payment-icon--giropay {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/giropay-3bdcc326f0a0c5b91730c313e5b7e44596fcbdce5c799d17d27d280d2ff2288f.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/giropay-6f774866c639b913c0d455fbc0d166710e827f55aff48b1931f02862edfcc65b.svg), none
}

.payment-icon--eghl {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/eghl-1bec3499bf657638c8e177124f3906cc02373e67abbbc576f4c2e895433d8d18.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/eghl-1cc79caf9f96e7bc06c62d2e4534eab8bbf9125facd30b2622c43c89419aea07.svg), none
}

.payment-icon--maestro {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/maestro-99a83dcd1235aec8c45531dc41bec9de11f92ecd5e9e26d96bc70e2b0667da12.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/maestro-d2055c6b416c46cf134f393e1df6e0ba31722b623870f954afd392092207889c.svg), none
}

.payment-icon--maybank {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/maybank-29e72d01b4892e0e2d0b37d3f17693ddf8812897a9ee619490b9bc114fae3dc7.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/maybank-22e74e2f92a10c2ff4916a5cdb120cf42e2d413fe805bce9db50224bb8764865.svg), none
}

.payment-icon--seveneleven {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/seveneleven-fd72a3b1906ffd56c4d1f9a9d2a1008e57991f4115c2123ca17c42e1ec239798.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/seveneleven-4f21d3f082426ca8921a142d3c4e12ac35810bee9130a7e1875621d9a1df6c44.svg), none
}

.payment-icon--paypal {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/paypal-773d91b9011ca57532c276e0427dbe98467e5f6c1f62f020d0c8457c5e6c01a0.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/paypal-49e4c1e03244b6d2de0d270ca0d22dd15da6e92cc7266e93eb43762df5aa355d.svg), none
}

.payment-icon--klarna-pay-later {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/klarna-pay-later-17a887aa381e2009cdbd62fe17155d765dfbfeea6c701f0ff709ca2410e3ccd9.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/klarna-pay-later-136c625018d85c89117f23eaf4c8b8a169f9380806832699b5a26c71b8db424c.svg), none
}

.payment-icon--atmbersama {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/atmbersama-d6639f412c6bc9d39ba6db32a03417295dea83898fa24073f882d32a1ba8bc26.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/atmbersama-1e799b2e6e61282e5749aacebf97c9da8f693b403c1eba5e5c7d0df211e26241.svg), none
}

.payment-icon--boleto {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/boleto-8a9e3eefa00e57020e6d74c6ff4ba49369d33a80a85efcaadd79f68921f09f80.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/boleto-50666ca3aee193c64e41a2803fe4a6d6c5e1a27d03c5e44ce97e3aa82fb6deb2.svg), none
}

.payment-icon--mash {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/mash-09f80b810527176c77a094630847bb46d07cff1f1c1cc75b266a4aaf8669cfd9.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/mash-0a6d9c501d7d275196826d26eee8dd9ff803d45467422edd9cae1808f5c64e00.svg), none
}

.payment-icon--dogecoin {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dogecoin-ed535ee926656b74c07472b944f5d91a2551ccaf569033d68469389a4dbf9e90.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dogecoin-40c07eb6559d1c47a2ac893d14a4d27cdfad770df3413fb3e49ab51a18c8961d.svg), none
}

.payment-icon--ethereum {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/ethereum-cbbea77b799afa96959fc5816d92c41eba41c7b5461a416cc1121cf13b0f16d7.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/ethereum-5ddd16af409ae6bd61c9ebb5b3f1bbec913e7e92fa98d4b0330e192ee1531839.svg), none
}

.payment-icon--diners-club {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/diners_club-69e17a1f992257d49f90aa04c481c42f5bc54c7c9394bbb887b615cd1f22409b.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/diners_club-16436b9fb6dd9060edb51f1c7c44e23941e544ad798282d6aef1604319562fba.svg), none
}

.payment-icon--paytm {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/paytm-29096d3537a22ad1b4a7dde643d93bbe883e0d0aaaa55e71086e8df0008eae4a.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/paytm-ea638dba9d6e858f0f6c38d0b744ab791cabe2016309511ada28a5da51140909.svg), none
}

.payment-icon--mobilepay {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/mobilepay-e27759f05b118e9fd0843bbfbc20317209499c71a06e1b81eea5f209df2a6809.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/mobilepay-beda5ed79ed7b64f535e8a4992d7f9e563ea8b6306d167aa4154e8e23c7d2fb6.svg), none
}

.payment-icon--circlek {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/circlek-2f17f3259ea7d07e086bd81182e361ab848e3495db877c006cbb29fecd54d002.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/circlek-58c13e0385bfa6b02cf8eceac9ac41af35d0c0e104cf438d6298e20fe68af1aa.svg), none
}

.payment-icon--laser {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/laser-0058f0e45f4569472080c2425bd165609676ac1c351a514582e01105ff77d250.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/laser-4e16350c611dc8bda5871718ba3b11010325ea8fba063529a64195e44e6242ec.svg), none
}

.payment-icon--hypercard {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/hypercard-723c40b9120198abe878de76ae0ef157d97faa248057bc89ea6df47985a16e23.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/hypercard-67044599e4f24c797a004443ff08fe824a2676f073cf232ad7790919b71fdf79.svg), none
}

.payment-icon--google-pay {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/google_pay-9d1f1f60e196e4c7631b75acacf5bc4103f9a1147ca7e7bd9c22dd2354a64f90.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/google_pay-c66a29c63facf2053bf69352982c958e9675cabea4f2f7ccec08d169d1856b31.svg), none
}

.payment-icon--citadele {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/citadele-149fb5e20d2257be259ed938f9256a28d0b886f500c1b23b6b11150518873e54.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/citadele-657beca0bcffe6910cb87833033e4e7d94c19c56af6d86ac4c638443c572078a.svg), none
}

.payment-icon--payzapp {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/payzapp-6977afe0a13364d5f76e91c8576bb94e716021c212362afe1901985ed7ab2de9.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/payzapp-9276d25b935c69d0eb05b150d5112c4c8301c3e17898e8d4834edb8dfdc01dd3.svg), none
}

.payment-icon--freecharge {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/freecharge-03e0278624035871df451b3a450f0fe41bd8b4d927313b08140cb0e1070f3900.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/freecharge-cda66ce4e67c8edc373c16b4697bdee48a929f4f346404e9654df4498da439f8.svg), none
}

.payment-icon--mobikwik {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/mobikwik-727e14230cbb604d3695bcb5874c12af68bd56e16e8479e65a27a0349e15677b.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/mobikwik-1badc6c3d0c5f99484fb2a2bd52090a88544a8949587162de411d9f288bf3f68.svg), none
}

.payment-icon--bancnet {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/bancnet-4e19a1f2fc9be0203cf20f51d90fc9f7ff3efecef90867eeb3e1397f8b0d008b.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/bancnet-843b4601d6aedcc483b8cfee40a687c1ec6af043e6fe67785d3f2e563202f165.svg), none
}

.payment-icon--dash {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dash-1f4d16afe57e4c7aadd59bb4abedb9ac046ac35b6b293378f836d01c96d29662.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dash-79c6868e5d0012a312de5a0bc1e383d98a316f7ee0492d40124671c4d9994aca.svg), none
}

.payment-icon--google-wallet {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/google_wallet-168118502d476c15077ef304edbe4429a7bfc8476cd64d59f64e14b5155737e9.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/google_wallet-1fa0064d9dc27dbdb3330fdfb06e58da9410cc5645c9e08e1112f59c9ea80be0.svg), none
}

.payment-icon--bogus {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/bogus-f4d060ed8a5587e2eae858bb86a912c2080c9e6c46d013b5059f12ec4634c153.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/bogus-13c83bc142b1d1b69905f99e38a897f75581e477a1233254ce01e64b3e3ae2b8.svg), none
}

.payment-icon--dankort {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dankort-13cfe74402564b77d68b5d5f4f3326794618fa1ec6ff509ee81a78cfb9060485.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/dankort-006ed30b0fc44a6c626315cc8b66af37bf3734882f2cd1522b1ba4baeb399c8d.svg), none
}

.payment-icon--arvato {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/arvato-ea273137a1ae2a103f0e2149a52df1926053a5dda3e0389ca678c9f695b173f4.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/arvato-7a38b4b987355cc4f1d96ddb32865b5dfd8a1d461d9b52c4b5ba885729e3dfec.svg), none
}

.payment-icon--collector-bank {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/collector_bank-49102714626ce847f51c327be7ffe2fc2033672ad99f63b9544e7e1daab9d660.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/collector_bank-8bc3ba608c2b27a621e0b85cfd742c459e965b061ce7c5d7ced56850018e04ba.svg), none
}

.payment-icon--elv {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/elv-9160c13e26e3a6812d77f319e0015965ad08f544448d424489d750a84c7de6a5.png);
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/elv-8dfb2600dc7fa9cfd33a33576542cdd929ed7b74d9f8ef6fb2862dfeb03342d7.svg), none
}

.payment-icon {
  border-radius: 0.21429em;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 2.71429em;
  height: 1.71429em
}

.radio__label__accessory .payment-icon {
  cursor: default;
  margin: -0.14286em 0 -0.42857em
}

.payment-icon--known {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  box-shadow: 0 6px 19px 0 rgba(0, 0, 0, 0.05)
}

.payment-icon--unknown {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5
}

.offsite-payment-gateway-logo {
  height: 24px;
  display: block;
  margin-top: -2px
}

.payment-method-list__item+.payment-method-list__item {
  margin-top: 0.5em
}

.payment-method-list__item-icon {
  vertical-align: middle;
  margin: -0.1em 0.25em 0 0
}

.payment-icon-list__more {
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 0.25em;
  display: inline-block
}

.wallet-card-summary-logo {
  display: inline-block;
  height: 1.28em;
  vertical-align: middle;
  width: auto
}

.amazon-payments__widget {
  width: 100%;
  height: 400px
}

.amazon-payments__link {
  display: block;
  margin-top: 1.5em
}

.blank-slate {
  padding: 1.5em;
  text-align: center
}

.blank-slate svg {
  margin-bottom: 1em;
  max-height: 150px
}

@media (min-width: 750px) {
  .blank-slate {
      padding-left: 4.5em;
      padding-right: 4.5em
  }
}

.blank-slate__title {
  display: block;
  margin-bottom: 0.65em
}

.blank-slate__icon {
  margin-bottom: 1em
}

.blank-slate__btn {
  margin-top: 1em
}

.notice {
  position: relative;
  display: table;
  opacity: 1;
  margin-bottom: 1.42857em;
  padding: 1em;
  border-radius: 4px;
  border: 1px solid rgba(51, 137, 195, 0.15);
  background-color: #eff8ff;
  -webkit-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out
}

.step__sections+.notice {
  margin-top: 0.71429em
}

.notice+.step__footer {
  margin-top: 0
}

.notice__icon {
  color: #197bbd;
  margin-top: -0.14286em;
  margin-right: 0.71429em
}

.notice__text {
  display: table-cell;
  width: 100%;
  color: #545454
}

.notice__close {
  margin-left: 0.71429em;
  position: relative;
  top: 0.14286em
}

.notice--error {
  border-color: rgba(218, 118, 118, 0.15);
  background-color: #ffebeb
}

.notice--error .notice__icon {
  color: #e32c2b
}

.notice--warning {
  border-color: rgba(155, 114, 0, 0.15);
  background-color: #fff8de
}

.notice--warning .notice__icon {
  color: #b88600
}

.notice--success {
  border-color: rgba(75, 166, 82, 0.15);
  background-color: #e9f8ec
}

.notice--success .notice__icon {
  color: #0ca227
}

.notice--hide {
  opacity: 0
}

.product td {
  padding-top: 1em
}

.product:first-child td {
  padding-top: 0
}

.product-thumbnail {
  width: 4.6em;
  height: 4.6em;
  border-radius: 8px;
  background: #fff;
  position: relative
}

.product-thumbnail::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 8px;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  z-index: 2
}

.product-thumbnail__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  z-index: 1
}

.product-thumbnail__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  margin: auto
}

.product-thumbnail__quantity {
  font-size: 0.85714em;
  font-weight: 500;
  line-height: 1.75em;
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75em;
  background-color: rgba(114, 114, 114, 0.9);
  color: #fff;
  box-sizing: border-box;
  min-width: 1.75em;
  height: 1.75em;
  padding: 0 0.58333em;
  position: absolute;
  right: -0.75em;
  top: -0.75em;
  z-index: 3
}

.product__price {
  white-space: nowrap
}

.product__description__name,
.product__description__variant,
.product__description__property {
  display: block
}

.product__description {
  width: 100%
}

.product__description .reduction-code {
  margin-top: 0.28571em
}

.product__description .reduction-code__text {
  color: #6c6c6c
}

.product__description__name {
  width: 1em;
  min-width: 100%
}

.product__status {
  position: relative;
  white-space: nowrap
}

.product__status--sold-out {
  color: #e32c2b
}

.product__status__icon {
  vertical-align: -0.15em
}

.logged-in-customer-information {
  zoom: 1
}

.logged-in-customer-information:after,
.logged-in-customer-information:before {
  content: "";
  display: table
}

.logged-in-customer-information:after {
  clear: both
}

.display-table .logged-in-customer-information {
  display: table;
  box-sizing: border-box;
  width: 100%
}

.logged-in-customer-information__avatar-wrapper {
  padding-right: 1em;
  white-space: nowrap;
  vertical-align: middle
}

.display-table .logged-in-customer-information__avatar-wrapper {
  display: table-cell
}

.no-js .logged-in-customer-information__avatar-wrapper,
.no-display-table .logged-in-customer-information__avatar-wrapper {
  float: left
}

.logged-in-customer-information__avatar {
  border-radius: 8px;
  background-size: cover;
  position: relative;
  max-width: none;
  width: 50px;
  height: 50px;
  overflow: hidden
}

.logged-in-customer-information__avatar:before {
  background-repeat: no-repeat;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url(//cdn.shopify.com/s/assets/checkout/default-gravatar-9f476c24f6f20308c2b6d87064984b5c01edc1d06bda86b79e3b58e393670e44.png);
  background-image: url(//cdn.shopify.com/s/assets/checkout/default-gravatar-5ad673c45c717c5f83e82f5087d841e9cdf55e4b38458bb0b7272b8585dd6b4f.svg), none
}

.logged-in-customer-information__paragraph {
  padding-top: 0.25em;
  vertical-align: middle
}

.display-table .logged-in-customer-information__paragraph {
  display: table-cell;
  width: 100%
}

.no-js .logged-in-customer-information__paragraph,
.no-display-table .logged-in-customer-information__paragraph {
  float: left
}

.logged-in-customer-newsletter {
  margin-top: 1.5em
}

@media (min-width: 1000px) {
  .alt-payment-list-container {
      padding-top: 1.5em
  }
}

@media (min-width: 1000px) {
  .alt-payment-list--center {
      text-align: center
  }
}

.alt-payment-list {
  font-size: 0
}

.alt-payment-list__item {
  position: relative;
  display: block;
  margin: 0 0 0.5em;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  vertical-align: middle
}

@media (min-width: 750px) {
  .alt-payment-list__item {
      display: inline-block;
      margin: 0.5em 0 0.5em 0.5em
  }
  .alt-payment-list__item:first-child {
      margin-left: 0
  }
}

.alt-payment-list__item__link {
  display: block;
  padding: 1.28571em 0;
  min-width: 100%
}

@media (min-width: 750px) {
  .alt-payment-list__item__link {
      padding: 0.85714em 0;
      min-width: 10.71429em
  }
}

.alt-payment-list__item__logo {
  display: block;
  margin: 0 auto;
  width: auto;
  height: 1.28571em
}

.alt-payment-list__item--amazon {
  background: #fad676
}

.alt-payment-list__item--amazon:hover {
  background-color: #f9ca4f
}

.no-js .alt-payment-list__item--amazon {
  display: none
}

.alt-payment-list__item--amazon .alt-payment-list__item__logo {
  -webkit-transform: translateY(0.28571em);
  transform: translateY(0.28571em);
  width: 6.57143em;
  height: 1.28571em
}

.alt-payment-list-amazon-button-image {
  max-height: none !important;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.alt-payment-list__item--paypal {
  background-color: #ffc520
}

.alt-payment-list__item--paypal:hover {
  background-color: #f6b600
}

.alt-payment-list__item--paypalv4 .alt-payment-list__item__link {
  padding: 0 !important;
  margin: 0 !important;
  vertical-align: top;
  line-height: 0 !important
}

.alt-payment-list__item--paypalv4 .alt-payment-list__item--paypal-btn--mobile {
  display: none
}

@media (max-width: 749px) {
  .alt-payment-list__item--paypalv4 .alt-payment-list__item--paypal-btn--desktop {
      display: none
  }
  .alt-payment-list__item--paypalv4 .alt-payment-list__item--paypal-btn--mobile {
      display: block
  }
}

.alt-payment-list__item--apple-pay {
  background-color: #000;
  font-family: -apple-system, 'Helvetica Neue', sans-serif;
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
  white-space: nowrap
}

.alt-payment-list__item--apple-pay .alt-payment-list__item__link {
  box-sizing: border-box;
  overflow: hidden;
  color: #fff
}

@media (min-width: 750px) {
  .alt-payment-list__item--apple-pay .alt-payment-list__item__link {
      padding: 0.85714em 0
  }
}

.alt-payment-list__item--apple-pay .alt-payment-list__item__link .alt-payment-list__item__logo {
  width: 3em;
  background: -webkit-named-image(apple-pay-logo-white) center center no-repeat;
  background-size: auto 100%
}

.google-pay-iframe-container {
  padding: 0
}

.gpay-iframe {
  border-radius: 4px;
  height: 54px;
  width: 100%;
  display: block
}

@media (min-width: 750px) {
  .gpay-iframe {
      height: 42px;
      width: 10.71429em
  }
}

.alt-payment-list__item--google-pay {
  height: 54px;
  display: none
}

@media (min-width: 750px) {
  .alt-payment-list__item--google-pay {
      height: 42px;
      width: 10.71429em
  }
}

.alt-payment-list__item--google-pay-visible {
  display: block
}

@media (min-width: 750px) {
  .alt-payment-list__item--google-pay-visible {
      display: inline-block
  }
}

.alternative-payment-separator {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  font-size: 0.85714em;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #737373;
  margin-top: 2.5em
}

@media (max-width: 749px) {
  .alternative-payment-separator {
      padding-bottom: 2em
  }
}

@media (min-width: 750px) and (max-width: 999px) {
  .alternative-payment-separator {
      margin-bottom: 2em
  }
}

.alternative-payment-separator::after,
.alternative-payment-separator::before {
  content: '';
  display: inline-block;
  height: 1px;
  background-color: #e6e6e6;
  -webkit-flex-grow: 1;
  flex-grow: 1
}

.alternative-payment-separator__content {
  display: inline-block;
  padding: 0 1em
}

@media (min-width: 1000px) {
  .dynamic-checkout {
      margin-top: 1.5em
  }
}

.dynamic-checkout__title {
  color: #737373;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  text-align: center
}

.dynamic-checkout__title::before,
.dynamic-checkout__title::after {
  content: '';
  border: 1px #e6e6e6 solid;
  border-bottom: 0;
  height: 0.5em;
  -webkit-flex: 1 0 2em;
  flex: 1 0 2em
}

.dynamic-checkout__title::before {
  border-right: 0;
  border-top-left-radius: 5px;
  margin-right: 1em
}

.dynamic-checkout__title::after {
  border-left: 0;
  border-top-right-radius: 5px;
  margin-left: 1em
}

.dynamic-checkout__content {
  border: 1px #e6e6e6 solid;
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.92857em 1.42857em 1.42857em;
  position: relative
}

@media (max-width: 999px) {
  .dynamic-checkout__content:empty {
      height: 74.8px
  }
}

@media (min-width: 750px) {
  .dynamic-checkout__content:empty {
      height: 47px
  }
}

.hidden {
  display: none !important
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute
}

@media (max-width: 749px) {
  .visually-hidden-on-mobile {
      border: 0;
      clip: rect(0, 0, 0, 0);
      clip: rect(0 0 0 0);
      width: 2px;
      height: 2px;
      margin: -2px;
      overflow: hidden;
      padding: 0;
      position: absolute
  }
}

@media (min-width: 1000px) {
  .visually-hidden-on-desktop {
      border: 0;
      clip: rect(0, 0, 0, 0);
      clip: rect(0 0 0 0);
      width: 2px;
      height: 2px;
      margin: -2px;
      overflow: hidden;
      padding: 0;
      position: absolute
  }
}

.visible-on-focus {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute
}

.visible-on-focus:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto
}

.no-js .shown-if-js {
  display: none
}

.js .hidden-if-js {
  display: none
}

.js .visually-hidden-if-js {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute
}

@media (max-width: 749px) {
  .hidden-on-mobile {
      display: none
  }
}

@media (min-width: 750px) {
  .shown-on-mobile {
      display: none
  }
}

@media (min-width: 750px) and (max-width: 999px) {
  .hidden-on-tablet {
      display: none
  }
}

@media (max-width: 749px) and (min-width: 1000px) {
  .shown-on-tablet {
      display: none
  }
}

@media (min-width: 1000px) {
  .hidden-on-desktop {
      display: none
  }
}

@media (max-width: 999px) {
  .shown-on-desktop {
      display: none
  }
}

.order-summary-toggle {
  background: #fafafa;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 1.25em 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  text-align: left;
  width: 100%
}

@media (min-width: 1000px) {
  .order-summary-toggle {
      display: none
  }
}

.order-summary-toggle__inner {
  zoom: 1
}

.order-summary-toggle__inner:after,
.order-summary-toggle__inner:before {
  content: "";
  display: table
}

.order-summary-toggle__inner:after {
  clear: both
}

.display-table .order-summary-toggle__inner {
  display: table;
  box-sizing: border-box;
  width: 100%
}

.order-summary-toggle__icon-wrapper {
  vertical-align: middle;
  padding-right: 0.75em;
  white-space: nowrap
}

.display-table .order-summary-toggle__icon-wrapper {
  display: table-cell
}

.no-js .order-summary-toggle__icon-wrapper,
.no-display-table .order-summary-toggle__icon-wrapper {
  float: left
}

.order-summary-toggle__icon {
  fill: #1c1b1b;
  -webkit-transition: fill 0.2s ease-in-out;
  transition: fill 0.2s ease-in-out
}

.order-summary-toggle:hover .order-summary-toggle__icon,
.order-summary-toggle:focus .order-summary-toggle__icon {
  fill: #020202
}

.order-summary-toggle__text {
  color: #1c1b1b;
  vertical-align: middle;
  -webkit-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  display: none
}

.order-summary-toggle:hover .order-summary-toggle__text,
.order-summary-toggle:focus .order-summary-toggle__text {
  color: #020202
}

.order-summary-toggle--show .order-summary-toggle__text--show,
.order-summary-toggle--hide .order-summary-toggle__text--hide {
  display: block
}

.display-table .order-summary-toggle--show .order-summary-toggle__text--show,
.display-table .order-summary-toggle--hide .order-summary-toggle__text--hide {
  display: table-cell;
  width: 100%
}

.no-js .order-summary-toggle--show .order-summary-toggle__text--show,
.no-display-table .order-summary-toggle--show .order-summary-toggle__text--show,
.no-js .order-summary-toggle--hide .order-summary-toggle__text--hide,
.no-display-table .order-summary-toggle--hide .order-summary-toggle__text--hide {
  float: left
}

.order-summary-toggle__total-recap {
  vertical-align: middle;
  text-align: right;
  padding-left: 0.75em;
  white-space: nowrap
}

.display-table .order-summary-toggle__total-recap {
  display: table-cell
}

.no-js .order-summary-toggle__total-recap,
.no-display-table .order-summary-toggle__total-recap {
  float: right
}

.total-recap__original-price {
  font-size: 0.85714em;
  color: #737373;
  display: block
}

.total-recap__final-price {
  font-size: 1.28571em;
  line-height: 1em;
  color: #333333
}

.order-summary-toggle__dropdown {
  vertical-align: middle;
  -webkit-transition: fill 0.2s ease-in-out;
  transition: fill 0.2s ease-in-out;
  fill: #1c1b1b
}

.order-summary-toggle:hover .order-summary-toggle__dropdown,
.order-summary-toggle:focus .order-summary-toggle__dropdown {
  fill: #020202
}

.g-recaptcha {
  border-radius: 4px;
  display: inline-block
}

.field--error .g-recaptcha {
  border-color: #e32c2b;
  box-shadow: 0 0 0 1px #e32c2b
}

.no-js .g-recaptcha {
  display: none
}

.g-recaptcha-nojs {
  max-width: 100%;
  width: 302px
}

.g-recaptcha-nojs__iframe {
  height: 423px;
  width: 100%
}

.g-recaptcha-nojs__input-wrapper {
  background: #f9f9f9;
  border: 1px #c1c1c1 solid;
  border-radius: 3px;
  box-sizing: border-box;
  margin-top: 0.5em;
  padding: 0.75em
}

.g-recaptcha-nojs__input {
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  height: 5em;
  padding: 0.42857em;
  resize: vertical;
  width: 100%
}

.field--error .g-recaptcha-nojs__input {
  border-color: #e32c2b;
  box-shadow: 0 0 0 1px #e32c2b
}

.sp-modal-toggle {
  opacity: 0;
  bottom: 0;
  border-radius: 0 5px 5px 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: 1;
  -webkit-animation: sp-fade-in 0.7s forwards cubic-bezier(0.2, 0.9, 0.3, 1);
  animation: sp-fade-in 0.7s forwards cubic-bezier(0.2, 0.9, 0.3, 1)
}

@media (max-width: 749px) {
  .sp-modal-toggle {
      -webkit-animation-name: fade-in;
      animation-name: fade-in
  }
}

.sp-modal-toggle:hover .sp-modal-toggle__phone-wrapper::after {
  border-color: #020202
}

.sp-modal-toggle:focus {
  outline-offset: -3px;
  outline-color: -webkit-focus-ring-color;
  outline-width: 4px
}

.sp-modal-toggle__icon-wrapper {
  display: block;
  position: relative
}

.sp-modal-toggle__icon-wrapper .sp-modal-toggle__icon {
  -webkit-animation: wobble 100ms 250ms linear 6;
  animation: wobble 100ms 250ms linear 6
}

.sp-modal-toggle__icon-wrapper::after {
  content: '';
  position: absolute;
  top: 3px;
  right: 8px;
  height: 6px;
  width: 6px;
  background-color: #fff;
  border: 1px solid #1c1b1b;
  border-radius: 50%;
  -webkit-animation: scale-up-bounce 400ms 850ms both;
  animation: scale-up-bounce 400ms 850ms both
}

.has-sp-modal body {
  height: auto;
  position: relative;
  min-height: 100%
}

.sp-modal-backdrop {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: visibility 1s cubic-bezier(0.2, 0.9, 0.3, 1), background-color 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: visibility 1s cubic-bezier(0.2, 0.9, 0.3, 1), background-color 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden;
  z-index: 999;
  overflow-y: scroll;
  -webkit-transform: scale(0);
  transform: scale(0)
}

.sp-modal-backdrop--visible {
  background-color: rgba(0, 0, 0, 0.18);
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1)
}

.sp-modal {
  color: #545454;
  opacity: 0;
  outline: none;
  position: absolute;
  -webkit-transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  -webkit-transform-origin: 0% 15%;
  transform-origin: 0% 15%;
  -webkit-transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden
}

@media (max-width: 749px) {
  .sp-modal {
      left: 50%;
      top: 2em;
      -webkit-transform: scale(1) translateX(-50%);
      transform: scale(1) translateX(-50%);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
      transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1)
  }
}

.sp-modal-backdrop--visible .sp-modal {
  opacity: 1;
  -webkit-transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  visibility: visible
}

@media (max-width: 749px) {
  .sp-modal-backdrop--visible .sp-modal {
      -webkit-transform: scale(1) translateX(-50%);
      transform: scale(1) translateX(-50%)
  }
}

.sp-modal--hidden .sp-modal {
  opacity: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1)
}

@media (max-width: 749px) {
  .sp-modal--hidden .sp-modal {
      -webkit-transform: scale(1) translateX(-50%);
      transform: scale(1) translateX(-50%)
  }
}

.sp-modal::before {
  background: #dddddd;
  background: -webkit-linear-gradient(45deg, white 0%, #ddd 50%);
  background: linear-gradient(45deg, white 0%, #ddd 50%);
  border-radius: 4px;
  content: '';
  display: block;
  height: 25px;
  left: 2px;
  margin-left: -8px;
  position: absolute;
  top: 1.8em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 25px
}

@media (max-width: 749px) {
  .sp-modal::before {
      display: none
  }
}

.sp-modal::after {
  box-shadow: 0 19px 47px rgba(0, 0, 0, 0.35);
  content: '';
  display: block;
  height: 100%;
  left: 5%;
  position: absolute;
  right: 5%;
  top: 0;
  width: 90%;
  z-index: -1
}

.sp-modal__frame {
  -webkit-transition: height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: height 0.2s cubic-bezier(0.2, 0.9, 0.3, 1)
}

.sp-modal--top {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%
}

.sp-modal--top::before {
  background: -webkit-linear-gradient(315deg, white 0%, #ddd 50%);
  background: linear-gradient(135deg, white 0%, #ddd 50%);
  left: 50%;
  bottom: -8px;
  margin-left: -12.5px;
  top: auto
}

.sp-modal--center {
  left: 50%;
  top: 50%;
  position: fixed
}

.sp-modal-backdrop--visible .sp-modal--center {
  -webkit-transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-left: 0
}

.sp-modal--center::before {
  display: none
}

.sp-modal--bottom-right,
.sp-modal--bottom-left {
  -webkit-transform-origin: 95% 0%;
  transform-origin: 95% 0%
}

.sp-modal--bottom-right::before,
.sp-modal--bottom-left::before {
  background: -webkit-linear-gradient(315deg, white 0%, #ddd 50%);
  background: linear-gradient(135deg, white 0%, #ddd 50%);
  left: auto;
  margin-left: -8px;
  right: 7px;
  top: -6px
}

.sp-modal--bottom-right {
  -webkit-transform-origin: 5% 0%;
  transform-origin: 5% 0%
}

.sp-modal__content {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  z-index: 1
}

.sp-modal__close {
  position: absolute;
  z-index: 2;
  top: 1em;
  right: 1em;
  height: 1em;
  width: 1em;
  color: #737373;
  -webkit-transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: color 0.3s cubic-bezier(0.3, 0, 0, 1)
}

.sp-modal__close:focus,
.sp-modal__close:hover,
.sp-modal__close:active {
  color: #545454
}

@-webkit-keyframes sp-fade-in {
  0% {
      opacity: 0;
      -webkit-transform: translateX(0.5em);
      transform: translateX(0.5em);
      visibility: hidden
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      visibility: visible
  }
}

@keyframes sp-fade-in {
  0% {
      opacity: 0;
      -webkit-transform: translateX(0.5em);
      transform: translateX(0.5em);
      visibility: hidden
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      visibility: visible
  }
}

.sp-popover {
  background-color: #fbfbfb;
  text-align: center
}

.sp-popover__header {
  color: #333333;
  font-weight: bold
}

.sp-popover__content {
  margin: 1em 0
}

.sp-popover__fieldset {
  max-width: 13em;
  position: relative;
  margin: 10px auto 0
}

.sp-popover__field {
  box-sizing: border-box;
  height: 4em;
  overflow: hidden;
  padding-bottom: 5px;
  text-align: center
}

.sp-popover__field::after {
  background-color: #1c1b1b;
  border-radius: 2px;
  bottom: 5px;
  content: '';
  height: 2px;
  left: 2.5%;
  position: absolute;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.54, -0.01, 0, 1);
  transition: -webkit-transform 0.2s cubic-bezier(0.54, -0.01, 0, 1);
  transition: transform 0.2s cubic-bezier(0.54, -0.01, 0, 1);
  transition: transform 0.2s cubic-bezier(0.54, -0.01, 0, 1), -webkit-transform 0.2s cubic-bezier(0.54, -0.01, 0, 1);
  width: 95%
}

.sp-popover__field.focus-with-keyboard:focus-within {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color
}

.sp-popover--loading .sp-popover__field::after,
.sp-popover--error .sp-popover__field::after,
.sp-popover--success .sp-popover__field::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1)
}

.sp-popover--error .sp-popover__field::after {
  background-color: #e32c2b
}

.sp-popover__input {
  color: #333;
  font-family: "Arial", "Helvetica Neue", "Helvetica", sans-serif;
  font-size: 2em;
  font-weight: bold;
  letter-spacing: 0.52em;
  outline: none;
  padding: 0.3em 0;
  position: absolute;
  top: 0;
  left: 4.5%;
  width: 7em
}

.sp-popover__input:focus {
  box-shadow: none
}

.sp-popover__input::-ms-clear {
  display: none
}

.sp-popover__field-underline-box {
  background-color: #9b9b9b;
  border-radius: 2px;
  bottom: 5px;
  height: 2px;
  left: 2.5%;
  position: absolute;
  width: 11.66667%
}

.field--active .sp-popover__field-underline-box--active {
  background-color: #1c1b1b
}

.sp-popover__field-underline-box--completed {
  opacity: 0.4
}

.sp-popover__field-underline-box:nth-of-type(2) {
  left: 19.16667%
}

.sp-popover__field-underline-box:nth-of-type(3) {
  left: 35.83333%
}

.sp-popover__field-underline-box:nth-of-type(4) {
  left: 52.5%
}

.sp-popover__field-underline-box:nth-of-type(5) {
  left: 69.16667%
}

.sp-popover__field-underline-box:nth-of-type(6) {
  left: 85.83333%
}

.sp-popover__footer {
  box-sizing: border-box;
  min-height: 3.5em;
  padding: 0.3em 1em;
  position: relative
}

.sp-popover__icon-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%)
}

.sp-popover__footer-error {
  bottom: 0.6em;
  clear: both;
  color: #e32c2b;
  display: none;
  font-size: 0.85714em;
  overflow: hidden;
  position: relative;
  text-align: center;
  top: -4em;
  -webkit-transform: none;
  transform: none
}

.sp-popover__footer--error .sp-popover__footer-error {
  display: block
}

.sp-popover__footer--success .sp-popover__footer-logo,
.sp-popover__footer--loading .sp-popover__footer-logo {
  display: none
}

.sp-popover__footer-logo .icon--shopify-pay {
  width: 38px;
  height: 17px
}

.sp-popover__icon-loading {
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 500ms 200ms cubic-bezier(0.3, 0, 0, 1);
  transition: -webkit-transform 500ms 200ms cubic-bezier(0.3, 0, 0, 1);
  transition: transform 500ms 200ms cubic-bezier(0.3, 0, 0, 1);
  transition: transform 500ms 200ms cubic-bezier(0.3, 0, 0, 1), -webkit-transform 500ms 200ms cubic-bezier(0.3, 0, 0, 1)
}

.sp-popover__footer--loading .sp-popover__icon-loading {
  -webkit-transform: scale(1);
  transform: scale(1)
}

.sp-popover__icon-success {
  -webkit-transform: scale(0);
  transform: scale(0)
}

.sp-popover__footer--success .sp-popover__icon-success {
  -webkit-animation: scale-up-bounce-spring 600ms cubic-bezier(0.08, 0.36, 0.32, 0.97);
  animation: scale-up-bounce-spring 600ms cubic-bezier(0.08, 0.36, 0.32, 0.97);
  -webkit-transform: scale(1);
  transform: scale(1)
}

.sp-popover__button-wrapper {
  position: relative;
  overflow: hidden
}

.sp-popover__trigger {
  opacity: 0;
  bottom: 0;
  border-radius: 0 5px 5px 0;
  padding: 0 1em;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  z-index: 1
}

.sp-popover__trigger:hover .sp-popover__phone-icon-pill::after {
  border-color: #020202
}

.sp-popover__trigger:focus {
  outline-offset: -3px;
  outline-color: -webkit-focus-ring-color;
  outline-width: 4px
}

.sp-popover__trigger-wrapper {
  display: block
}

.sp-popover__trigger--fadein {
  -webkit-animation: sp-field-button-in 0.7s forwards cubic-bezier(0.2, 0.9, 0.3, 1);
  animation: sp-field-button-in 0.7s forwards cubic-bezier(0.2, 0.9, 0.3, 1)
}

@media (max-width: 749px) {
  .sp-popover__trigger--fadein {
      -webkit-animation-name: fade-in;
      animation-name: fade-in
  }
}

.sp-popover__error-pane {
  padding: 2em
}

.sp-popover__error-pane-icon {
  margin-bottom: 1.3em;
  -webkit-transform: scale(0);
  transform: scale(0)
}

.sp-popover__error-pane--visible .sp-popover__error-pane-icon {
  -webkit-animation: scale-up-bounce-spring 600ms 200ms cubic-bezier(0.08, 0.36, 0.32, 0.97) forwards;
  animation: scale-up-bounce-spring 600ms 200ms cubic-bezier(0.08, 0.36, 0.32, 0.97) forwards
}

.sp-popover__error-pane-title,
.sp-popover__error-pane-content,
.sp-popover__error-pane-footer {
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.08, 0.36, 0.32, 0.97);
  transition: all 0.3s cubic-bezier(0.08, 0.36, 0.32, 0.97);
  -webkit-transition-delay: 200ms;
  transition-delay: 200ms
}

.sp-popover__error-pane--visible .sp-popover__error-pane-title,
.sp-popover__error-pane--visible .sp-popover__error-pane-content,
.sp-popover__error-pane--visible .sp-popover__error-pane-footer {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.sp-popover__error-pane-title {
  color: #333333;
  font-weight: 500;
  margin-bottom: 0.8em;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%)
}

.sp-popover__error-pane-content {
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%)
}

.sp-popover__error-pane-footer {
  padding-top: 2em;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition-delay: 230ms;
  transition-delay: 230ms
}

.sp-popover__banner {
  position: relative;
  height: 24px;
  margin-bottom: 20px
}

.sp-popover__banner-message {
  color: #737373;
  font-size: 0.85714em;
  position: absolute;
  width: 100%
}

.sp-popover__phone-icon-pill {
  position: relative
}

.sp-popover__phone-icon-pill::after {
  content: '';
  position: absolute;
  top: 3px;
  right: 8px;
  height: 6px;
  width: 6px;
  background-color: #fff;
  border: 1px solid #1c1b1b;
  border-radius: 50%;
  -webkit-animation: scale-up-bounce 400ms both;
  animation: scale-up-bounce 400ms both
}

.sp-popover__field-button--svg-animation {
  -webkit-animation: wobble 100ms 250ms linear 6;
  animation: wobble 100ms 250ms linear 6
}

.sp-popover__banner-message--sending {
  -webkit-animation: fade-out 500ms 2s cubic-bezier(0.3, 0, 0, 1) both;
  animation: fade-out 500ms 2s cubic-bezier(0.3, 0, 0, 1) both
}

.sp-popover__banner-message--sent {
  -webkit-animation: fade-in 500ms 2.25s cubic-bezier(0.3, 0, 0, 1) both;
  animation: fade-in 500ms 2.25s cubic-bezier(0.3, 0, 0, 1) both
}

.sp-popover__banner-message--sent .sp-popover__phone-icon-svg {
  -webkit-animation: wobble 100ms 2.25s linear 6;
  animation: wobble 100ms 2.25s linear 6
}

.sp-popover__banner-message--sent .sp-popover__phone-icon::after {
  content: '';
  position: absolute;
  top: -3px;
  right: 1px;
  height: 6px;
  width: 6px;
  background: #1c1b1b;
  border: 1px solid #fff;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: scale-up-bounce 400ms 2.65s forwards;
  animation: scale-up-bounce 400ms 2.65s forwards
}

.sp-popover__phone-icon {
  position: relative;
  display: inline-block;
  text-align: right
}

@-webkit-keyframes sp-field-button-in {
  0% {
      opacity: 0;
      -webkit-transform: translateX(0.5em);
      transform: translateX(0.5em);
      visibility: hidden
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      visibility: visible
  }
}

@keyframes sp-field-button-in {
  0% {
      opacity: 0;
      -webkit-transform: translateX(0.5em);
      transform: translateX(0.5em);
      visibility: hidden
  }
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
      transform: translateX(0);
      visibility: visible
  }
}

.has-popover body {
  height: auto;
  position: relative;
  min-height: 100%
}

.popover-backdrop {
  background: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: all 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: all 1s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden;
  z-index: 999
}

.popover-backdrop--visible {
  background: rgba(0, 0, 0, 0.18);
  visibility: visible
}

.popover-backdrop--hidden {
  display: none
}

.popover {
  color: #545454;
  opacity: 0;
  outline: none;
  position: absolute;
  -webkit-transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  -webkit-transform-origin: 0% 15%;
  transform-origin: 0% 15%;
  -webkit-transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), opacity 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1), -webkit-transform 0.2s 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  visibility: hidden;
  width: 23.5em
}

@media (max-width: 749px) {
  .popover {
      left: 50%;
      top: 2em;
      margin-left: -11.75em;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      -webkit-transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
      transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1)
  }
}

.popover-backdrop--visible .popover {
  opacity: 1;
  -webkit-transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: scale(1) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  visibility: visible
}

@media (max-width: 749px) {
  .popover-backdrop--visible .popover {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

.popover--hidden .popover {
  opacity: 0;
  -webkit-transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: all 0.2s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1);
  transform: scale(0.1) matrix3d(1, 0, 0, 0, 0, 0.8, 0.5, -0.002, 0, -0.4, 0.8, 0, 0, 0, 0, 1)
}

@media (max-width: 749px) {
  .popover--hidden .popover {
      -webkit-transform: scale(1);
      transform: scale(1)
  }
}

.popover::before {
  background: #dddddd;
  background: -webkit-linear-gradient(45deg, white 0%, #ddd 50%);
  background: linear-gradient(45deg, white 0%, #ddd 50%);
  border-radius: 4px;
  content: '';
  display: block;
  height: 25px;
  left: 2px;
  margin-left: -8px;
  position: absolute;
  top: 1.8em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 25px
}

@media (max-width: 749px) {
  .popover::before {
      display: none
  }
}

.popover::after {
  box-shadow: 0 19px 47px rgba(0, 0, 0, 0.35);
  content: '';
  display: block;
  height: 100%;
  left: 5%;
  position: absolute;
  right: 5%;
  top: 0;
  width: 90%;
  z-index: -1
}

.popover-pane {
  opacity: 1;
  -webkit-transition: all 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.2, 0.9, 0.3, 1)
}

.popover-pane--hidden {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1
}

.popover--bottom-right,
.popover--bottom-left {
  -webkit-transform-origin: 95% 0%;
  transform-origin: 95% 0%
}

.popover--bottom-right::before,
.popover--bottom-left::before {
  background: -webkit-linear-gradient(315deg, white 0%, #ddd 50%);
  background: linear-gradient(135deg, white 0%, #ddd 50%);
  left: auto;
  margin-left: -8px;
  right: 7px;
  top: -6px
}

.popover--bottom-right {
  -webkit-transform-origin: 5% 0%;
  transform-origin: 5% 0%
}

.popover--top {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%
}

.popover--top::before {
  background: -webkit-linear-gradient(315deg, white 0%, #ddd 50%);
  background: linear-gradient(135deg, white 0%, #ddd 50%);
  left: 50%;
  bottom: -8px;
  margin-left: -12.5px;
  top: auto
}

.popover--center {
  left: 50%;
  top: 50%;
  position: fixed
}

.popover-backdrop--visible .popover--center {
  -webkit-transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: opacity 0.3s 0.3s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-left: 0
}

.popover--center::before {
  display: none
}

.popover__content-wrapper {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  z-index: 1
}

.popover__content {
  padding: 2.2em 1.8em 1.5em;
  -webkit-transition: all 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  transition: all 0.5s cubic-bezier(0.2, 0.9, 0.3, 1);
  -webkit-transform: translateY(0);
  transform: translateY(0)
}

.popover-pane--hidden .popover__content {
  -webkit-transform: translateY(10%);
  transform: translateY(10%)
}

.popover__close {
  position: absolute;
  z-index: 2;
  top: 1em;
  right: 1em;
  height: 1em;
  width: 1em;
  color: #737373;
  -webkit-transition: color 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: color 0.3s cubic-bezier(0.3, 0, 0, 1)
}

.popover__close:focus,
.popover__close:hover,
.popover__close:active {
  color: #545454
}

.review-block {
  display: -webkit-flex;
  display: flex
}

.review-block ~ .review-block {
  margin-top: 0.85714em;
  padding-top: 0.85714em;
  border-top: 1px solid #e6e6e6
}

.review-block__inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1;
  flex: 1 1
}

@media (max-width: 749px) {
  .review-block__inner {
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap
  }
}

.review-block__label {
  color: #737373;
  padding-right: 1.14286em;
  -webkit-flex: 0 1 5em;
  flex: 0 1 5em
}

@media (max-width: 749px) {
  .review-block__label {
      padding-bottom: 0.28571em
  }
}

.review-block__content {
  -webkit-flex: 5;
  flex: 5 1;
  color: #333333;
  padding-right: 1.14286em
}

@media (max-width: 749px) {
  .review-block__content {
      width: 100%;
      -webkit-flex: 0 1 auto;
      flex: 0 1 auto
  }
}

.review-block__link {
  max-width: 10em;
  font-size: 0.85714em
}

.review-block__billing-address {
  margin-top: 0.25em
}

.flag-selector {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  -webkit-transition: all 0.2s cubic-bezier(0, 0.34, 0.1, 0.98);
  transition: all 0.2s cubic-bezier(0, 0.34, 0.1, 0.98);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 0.71429rem
}

.no-js .flag-selector,
.no-anyflexbox .flag-selector {
  display: table
}

.flag-selector::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -10px;
  height: 20px;
  border-left: 1px solid transparent
}

.flag-selector--visible::after {
  border-left-color: #d9d9d9;
  -webkit-animation: scale-up 0.1s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
  animation: scale-up 0.1s cubic-bezier(0, 0.34, 0.1, 0.98) forwards
}

.flag-selector--hidden {
  display: none
}

.flag-selector--focus {
  background: rgba(28, 27, 27, 0.25)
}

.flag-selector--focus::after {
  display: none
}

.flag-selector__caret {
  display: block;
  margin-left: 0.5em
}

.no-js .flag-selector__caret,
.no-anyflexbox .flag-selector__caret {
  display: table-cell;
  vertical-align: middle
}

.flag-selector--visible .flag-selector__caret {
  -webkit-animation: fade-in 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
  animation: fade-in 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards
}

.flag-selector--focus .flag-selector__caret {
  fill: #1c1b1b
}

.flag-selector__icon {
  height: 20px;
  width: 27px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 2px;
  box-sizing: border-box;
  -webkit-filter: saturate(1.3) hue-rotate(-5deg);
  filter: saturate(1.3) hue-rotate(-5deg);
  opacity: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out
}

.no-js .flag-selector__icon,
.no-anyflexbox .flag-selector__icon {
  display: table-cell;
  vertical-align: middle
}

.flag-selector--visible .flag-selector__icon {
  -webkit-animation: scale-up-bounce 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards;
  animation: scale-up-bounce 0.2s cubic-bezier(0, 0.34, 0.1, 0.98) forwards
}

.flag-selector--hidden .flag-selector__icon {
  -webkit-animation: scale-down-bounce 0.2s cubic-bezier(0.57, 0.31, 0.42, 0.91) forwards;
  animation: scale-down-bounce 0.2s cubic-bezier(0.57, 0.31, 0.42, 0.91) forwards
}

.flag-selector__select {
  background-color: inherit;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0
}

.element-toggler {
  -webkit-transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  transition: all 0.3s cubic-bezier(0.3, 0, 0, 1);
  overflow: hidden;
  clear: both;
  opacity: 0;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0
}

.element-toggler--visible {
  opacity: 1
}

.placeholder-line {
  height: 1em;
  border-radius: 4px;
  border: 1px solid transparent;
  box-sizing: border-box;
  background-color: #e6e6e6
}

.placeholder-line+.placeholder-line {
  margin-top: 0.5em
}

.placeholder-line--loose-vertical+.placeholder-line--loose-vertical {
  margin-top: 1em
}

.placeholder-line--40 {
  width: 40%
}

.placeholder-line--45 {
  width: 45%
}

.placeholder-line--50 {
  width: 50%
}

.placeholder-line--60 {
  width: 60%
}

.placeholder-line--70 {
  width: 70%
}

.placeholder-line--75 {
  width: 75%
}

.placeholder-line--90 {
  width: 90%
}

.placeholder-line--100 {
  width: 100%
}

.skip-to-content {
  box-sizing: border-box;
  background-color: white;
  text-align: center;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
  margin: -1px
}

.skip-to-content:focus {
  margin: 0;
  padding: 1em;
  clip: auto;
  width: 100%;
  height: auto;
  overflow: visible
}

.tags-list {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap
}

.tags-list .tag {
  margin-top: 0.85714em;
  margin-right: 0.85714em
}

.tags-list .tag:last-child {
  margin-right: 0
}

@media (min-width: 1000px) {
  .tags-list {
      float: left
  }
}

.tag {
  border-radius: 4px;
  background-color: rgba(108, 108, 108, 0.11);
  color: #6c6c6c;
  font-size: 0.85714em;
  padding: 0.85714em;
  overflow: hidden
}

.tag__wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: 100%
}

.tag__text {
  color: #303030;
  font-size: 1.14286em;
  font-weight: 500;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap
}

.tag__button {
  margin-left: 0.85714em
}

.tag__button .icon-svg {
  stroke: rgba(108, 108, 108, 0.9)
}

.tag__button:hover .icon-svg,
.tag__button:focus .icon-svg {
  stroke: #303030
}

.page--stock-problems.page--logo-main .breadcrumb,
.page--stock-problems.page--logo-sidebar .main__header,
.page--stock-problems.page--logo-banner .main__header {
  display: none
}

.stock-problem-table__header th {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 1.5em;
  white-space: nowrap;
  font-weight: 500;
  color: #333333
}

.stock-problems-table td {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #e6e6e6
}

.product__clear-btn {
  position: absolute;
  right: -2.5em;
  top: 50%;
  margin-top: -8px
}

@media (max-width: 749px) {
  .product__clear-btn {
      display: none
  }
}

.exclamation-mark {
  color: #737373;
  fill: currentColor;
  stroke: currentColor
}

.exclamation-mark__circle {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0
}

.cssanimations .exclamation-mark__circle {
  stroke-dasharray: 151;
  stroke-dashoffset: 151;
  -webkit-animation: draw-stroke 0.6s 0.4s cubic-bezier(0.3, 0, 0, 1) forwards;
  animation: draw-stroke 0.6s 0.4s cubic-bezier(0.3, 0, 0, 1) forwards
}

.cssanimations .exclamation-mark__line {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  -webkit-animation: draw-stroke 0.3s 0.8s cubic-bezier(0.3, 0, 0, 1) forwards;
  animation: draw-stroke 0.3s 0.8s cubic-bezier(0.3, 0, 0, 1) forwards
}

.exclamation-mark__dot {
  -webkit-transform-origin: 50% 50% 0;
  transform-origin: 50% 50% 0
}

.cssanimations .exclamation-mark__dot {
  -webkit-animation: scale-up 0.3s 1.01s cubic-bezier(0.3, 0, 0, 1) both;
  animation: scale-up 0.3s 1.01s cubic-bezier(0.3, 0, 0, 1) both
}

.os-header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  margin: 0
}

@media (min-width: 750px) {
  .os-header {
      margin: 0 0 -0.5em
  }
}

.os-header__heading {
  -webkit-flex-grow: 1;
  flex-grow: 1
}

.os-order-number {
  display: block;
  margin-bottom: 0.25em
}

.os-header__title {
  font-size: 1.57143em
}

.os-header__hanging-icon {
  margin-right: 0.75em;
  stroke: #1c1b1b
}

@media (min-width: 750px) and (max-width: 999px) {
  .os-header__hanging-icon {
      position: absolute;
      right: 100%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-right: 1.5em
  }
}

@media (min-width: 1300px) {
  .os-header__hanging-icon {
      position: absolute;
      right: 100%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-right: 1.5em
  }
}

.map {
  overflow: hidden
}

.content-box__row:first-child .map {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px
}

.map__iframe {
  display: block;
  height: 200px;
  width: calc(100% + 1px);
  border: 0;
  z-index: 0
}

.map__placeholder {
  height: 200px;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.gm-style .gm-style-iw {
  color: #333333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 400;
  padding-top: 5px;
  text-align: center;
  width: 100%
}

.gm-style .gm-style-iw+div {
  display: none
}

.os-timeline {
  margin: 0;
  padding: 0
}

.os-timeline-step__title {
  display: block;
  color: #737373
}

.os-timeline-step__date {
  display: none
}

.os-timeline-step--selected .os-timeline-step__icon {
  fill: #1c1b1b
}

.os-timeline-step--selected .os-timeline-step__title {
  color: #1c1b1b;
  font-weight: 500
}

.os-timeline-step--selected .os-timeline-step__date {
  display: block;
  font-size: 0.85714em;
  color: #737373
}

@media (max-width: 749px) {
  .os-timeline-step:not(.os-timeline-step--current) {
      display: none
  }
  .os-timeline-step {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center
  }
  .os-timeline-step__title {
      -webkit-flex-grow: 1;
      flex-grow: 1
  }
}

@media (min-width: 750px) {
  .os-timeline {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      overflow: hidden
  }
  .os-timeline-step {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-items: center;
      align-items: center;
      text-align: center;
      max-width: 25%;
      position: relative;
      padding-top: 20px
  }
  .os-timeline-step:before,
  .os-timeline-step:after {
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      top: 8.5px;
      right: 50%
  }
  .os-timeline-step:before {
      background: #e6e6e6;
      width: 2000px
  }
  .os-timeline-step:first-child:after {
      background: #fff;
      left: 0;
      z-index: 2
  }
  .os-timeline-step__icon {
      fill: #b3b3b3;
      background: #fff;
      background: #fff;
      position: absolute;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 3
  }
  .os-timeline-step__title {
      display: block;
      color: #737373
  }
  .os-timeline-step__date {
      display: none
  }
  .os-timeline-step--selected:before {
      background: #1c1b1b;
      z-index: 1
  }
  .os-timeline-step--selected .os-timeline-step__icon {
      fill: #1c1b1b
  }
  .os-timeline-step--selected .os-timeline-step__title {
      color: #1c1b1b;
      font-weight: 500
  }
  .os-timeline-step--selected .os-timeline-step__date {
      display: block
  }
}

.os-step__title {
  margin: 0.125em 0 0.5em
}

.os-step__description+.os-step__description {
  margin-top: 0.75em
}

.os-step__info {
  margin-top: 1em
}

.os-step__info-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 0.75em
}

.os-step__special-description+.os-step__special-description {
  border-top: 1px #e6e6e6 solid;
  margin-top: 1.5em;
  padding-top: 1.5em
}

.tracking-info {
  border-top: 1px solid #e6e6e6;
  margin-top: 1.14286em;
  padding-top: 1.14286em
}

.tracking-info__number {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  color: #737373
}

.shipment-information {
  padding: 1em
}

.shipment-information__items td {
  padding-top: 1em
}

.shipment-information__item:first-child td {
  padding-top: 0
}

.shipment-information__image {
  width: 2.5em
}

.shipment-information__image .product-thumbnail {
  width: 2.5em;
  height: 2.5em;
  border-right: 6px
}

.shipment-information__image .product-thumbnail__wrapper {
  border-right: 6px
}

.shipment-information__image .product-thumbnail__quantity {
  font-size: 11px;
  top: -0.65em;
  padding: 0 0.55em
}
 :root {
  --input-padding-x: .75rem;
  --input-padding-y: .75rem;
}

html,
body {
  height: 100%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    
}

.circle-loader {
  border: 2.5px solid rgba(0, 0, 0, 0.2);
  border-left-color: #5cb85c;
  -webkit-animation: loader-spin 1.2s infinite linear;
          animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: 3.5em;
  height: 3.5em;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: #5cb85c;
  -webkit-transition: border 500ms ease-out;
  transition: border 500ms ease-out;
}

.checkmark {
  display: none;
}
.checkmark.draw:after {
  -webkit-animation-duration: 800ms;
          animation-duration: 800ms;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
  -webkit-animation-name: checkmark;
          animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
          transform: scaleX(-1) rotate(135deg);
}
.checkmark:after {
  opacity: 1;
  height: 1.75em;
  width: 0.875em;
  -webkit-transform-origin: left top;
          transform-origin: left top;
  border-right: 3px solid #5cb85c;
  border-top: 3px solid #5cb85c;
  content: '';
  left: 0.77em;
  top: 1.75em;
  position: absolute;
}

@-webkit-keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.875em;
    opacity: 1;
  }
  40% {
    height: 1.75em;
    width: 0.875em;
    opacity: 1;
  }
  100% {
    height: 1.75em;
    width: 0.875em;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 0.875em;
    opacity: 1;
  }
  40% {
    height: 1.75em;
    width: 0.875em;
    opacity: 1;
  }
  100% {
    height: 1.75em;
    width: 0.875em;
    opacity: 1;
  }
}


.react-phone-number-input__row {
  margin-top: 10px;
}


.react-phone-number-input__icon-image{
  vertical-align: top;
}
.react-phone-number-input__icon{
  border: none;
}

.mobile-field-image-container{
  display: inline-block;
}

.mobile-valid-box{
  height: 40px;
  width: 40px;
}
.react-phone-number-input {
  background-repeat:no-repeat;
  background-size:contain;
  border-right: none;
}

.valid-mobile {
  background-repeat:no-repeat;
  background-position: center right calc(1rem / 20);
  padding-right: 2.25rem;
  background-size: calc(3.5rem / 2) calc(2.25rem / 2);
  border: 1px solid;
  border-color: #CFD4D9;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-left: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
}

.invalid-mobile { 
  padding-right: 2.25rem;
  border: 1px solid;
  border-color: #dc3545;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
  border-left: none;
  background-size: calc(3.5rem / 2) calc(2.25rem / 2);
  background-repeat:no-repeat;
  background-position: center right calc(1rem / 20);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
}

.-mobile{
  border: 1px solid #ced4da;
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}



.invalid-mobile-box{
  border-color: #dc3545;
}
.mobile-icon{
  width: 20px;
  height: 20px;
}
.input-group-text{
  padding:0;
  background-color: transparent;
}
.react-phone-number-input__input{
  border-bottom: none;
  border: none;
  padding-top: 0px !important ;
  padding-bottom: 0px !important ; 
  
}
.mobile-label{
  font-size: 12px;
  color: #777;
  margin-top: 4px;
  margin-left: 10px;
  padding: 0px !important; 
  
}
@-webkit-keyframes autofill {
  100% {
      background: transparent;
      color: inherit;
  }
}
.form-container-checkout {
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-control:focus {
  border-color: #313438;
  box-shadow: none;
}

.form-control {
    height: calc(2.25rem + 13px);
}



.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #CFD4D9;
  background-position: center right calc(1rem / 2.8);
  
}



.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color:#313438;
  box-shadow: none;
}


.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  box-shadow: none;
}

* {
  box-sizing: border-box;
}

.__PrivateStripeElement{
  top: 2px;
}
.StripeElement {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 12px;
  width: auto;
  margin-bottom: 0px;
  height: 45px;
}
.StripeElement--complete{
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 12px;
  width: auto;
  margin-bottom: 0px;
  background-size: calc(3.5rem / 2) calc(2.25rem / 2);
  background-repeat:no-repeat;
  background-position: center right calc(1rem / 20);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
}

.StripeElement--focus {
  border: 1px solid;
  border-color:#313438;
  outline: 0;
}


.StripeElement-invalid{
  border: 1px solid #dc3545;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 12px;
  width: auto;
  margin-bottom: 15px;
  background-size: calc(3.5rem / 2) calc(2.25rem / 2);
  background-repeat:no-repeat;
  background-position: center right calc(1rem / 20);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
}

.intercom-launcher-mobile{
  margin-bottom: '40px';
  padding-bottom: '40px';
}

.StripeElement-invalid:focus{
  border: 1px solid #dc3545;
}

.StripeElement--outlined{
  border: 1px solid #dc3545;
}

.ElementsApp input {
  font-size: 12px;
}


@media screen and (max-width: 600px) {
  .StripeElement {
    margin-bottom: 0px;
  }
  
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: 0 auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: .75rem .75rem;
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: .25rem;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}
.form-checkbox{
  -webkit-appearance: checkbox;
}


.checkout-button-mobile {
  position:fixed;
  left:0px;
  bottom:0px;
  width:100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0px !important;
}

.checkout-button-desktop {
  margin-right: 'auto';
  margin-top: '0px';
  padding-top: 15px;
  padding-bottom: 15px;
 }


@media screen and (max-width: 600px) {
  .checkout-button-desktop {
   display: none;
  }
}

@media screen and (min-width: 600px) {
  .checkout-button-mobile {
   display: none;
  }
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(.75rem + .75rem * (2 / 3));
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(.75rem / 3);
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(.75rem / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}
select:focus,
input{
  font-size: 16px !important;
}
select:focus,
select{
  font-size: 16px;
}
